<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1 class="mb-0">Job Relocation Manage</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <validation-observer ref="formRef" #default="{ invalid }">
          <b-card>
            <b-tabs v-model="activeTab" fill>
              <b-tab title="Information" active>
                <b-form @submit.prevent="onSubmit">
                  <b-row>
                    <b-col>
                      <b-form-group label="Reference No." label-for="v-refnum">
                        <b-form-input
                          disabled
                          v-model="formData.refnum"
                          id="v-refnum"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                      <b-form-group label="Start Date" label-for="v-start_date">
                        <b-form-input
                          id="v-start_date"
                          v-model="previewStartDate"
                          type="date"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                      <b-form-group label="End Date" label-for="v-end_date">
                        <b-form-input
                          id="v-end_date"
                          v-model="previewEndDate"
                          type="date"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="Description"
                        label-for="v-description"
                      >
                        <quill-editor
                          ref="quillEditorRefDescriptions"
                          v-model="contentDescriptions"
                          id="v-description"
                          :options="{ theme: 'snow' }"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group label="Engineer A" label-for="v-emp_id_1">
                        <v-select
                          id="v-emp_id_1"
                          v-model="formData.emp_id_1"
                          :options="users"
                          :reduce="(users) => users.value"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Engineer B" label-for="v-emp_id_2">
                        <v-select
                          id="v-emp_id_2"
                          v-model="formData.emp_id_2"
                          :options="users"
                          :reduce="(users) => users.value"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="From Location"
                        label-for="v-from_loc"
                      >
                        <b-form-input
                          v-model="formData.from_loc"
                          id="v-from_loc"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group label="To Location" label-for="v-to_loc">
                        <b-form-input v-model="formData.to_loc" id="v-to_loc" />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="text-right">
                      <CompanySearch @on-select="(v) => onSelectCompany(v)" />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Company Name"
                        label-for="v-ticket-no"
                      >
                        <b-form-input
                          v-model="formData.company_name"
                          id="v-ticket-no"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12">
                      <b-form-group label="Remarks" label-for="v-remarks">
                        <quill-editor
                          ref="quillEditorRefRemarks"
                          v-model="contentRemarks"
                          id="v-remarks"
                          :options="{ theme: 'snow' }"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Details</h2>
                    </b-col>
                    <b-col class="text-right">
                      <RelocationItemSearch
                        @on-select="(v) => onSelectItem(v)"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group label="Item" label-for="v-item_id">
                        <b-form-input
                          disabled
                          id="v-item_id"
                          v-model="formData.item_name"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group label="Quantity" label-for="v-qty">
                        <b-form-input
                          type="number"
                          id="v-qty"
                          v-model.number="formData.qty"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="UOM" label-for="v-uom">
                        <b-form-select
                          id="v-uom"
                          v-model="formData.uom"
                          :options="uoms"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Serial No." label-for="v-sr_no">
                        <b-form-input id="v-sr_no" v-model="formData.sr_no" />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Barcode" label-for="v-barcode">
                        <b-form-input
                          id="v-barcode"
                          v-model="formData.barcode"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group label="Staff" label-for="v-emp_id">
                        <v-select
                          id="v-emp_id"
                          v-model="formData.emp_id"
                          :options="users"
                          :reduce="(users) => users.value"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Movement Status"
                        label-for="v-m_status"
                      >
                        <b-form-select
                          id="v-m_status"
                          v-model="formData.m_status"
                          :options="movementStatus"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Condition Status"
                        label-for="v-cond_status"
                      >
                        <b-form-select
                          id="v-cond_status"
                          v-model="formData.cond_status"
                          :options="conditionStatus"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Scan Start Date"
                        label-for="v-scn_s_date"
                      >
                        <b-form-input
                          id="v-scn_s_date"
                          v-model="previewScanStartDate"
                          type="date"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Scan End Date"
                        label-for="v-scn_e_date"
                      >
                        <b-form-input
                          id="v-scn_e_date"
                          v-model="previewScanEndDate"
                          type="date"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Scan Start Employee"
                        label-for="v-scn_s_emp_id"
                      >
                        <v-select
                          id="v-scn_s_emp_id"
                          v-model="formData.scn_s_emp_id"
                          :options="users"
                          :reduce="(users) => users.value"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Scan End Employee"
                        label-for="v-scn_e_emp_id"
                      >
                        <v-select
                          id="v-scn_e_emp_id"
                          v-model="formData.scn_e_emp_id"
                          :options="users"
                          :reduce="(users) => users.value"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Scan Start Source"
                        label-for="v-scn_s_src"
                      >
                        <b-form-input
                          id="v-scn_s_src"
                          v-model="formData.scn_s_src"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Scan End Source"
                        label-for="v-scn_e_src"
                      >
                        <b-form-input
                          id="v-scn_e_src"
                          v-model="formData.scn_e_src"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Scan Received"
                        label-for="v-scn_rcvd"
                      >
                        <b-form-input
                          id="v-scn_rcvd"
                          v-model="previewScanReceived"
                          type="date"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Scan Received Employee"
                        label-for="v-scn_rcvd_emp_id"
                      >
                        <v-select
                          id="v-scn_rcvd_emp_id"
                          v-model="formData.scn_rcvd_emp_id"
                          :options="users"
                          :reduce="(users) => users.value"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Scan Received Source"
                        label-for="v-scn_rcvd_src"
                      >
                        <b-form-input
                          id="v-scn_rcvd_src"
                          v-model="formData.scn_rcvd_src"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="Condition Remarks"
                        label-for="v-cond_remarks"
                      >
                        <quill-editor
                          ref="quillEditorRefConditionRemarks"
                          v-model="contentConditionRemarks"
                          id="v-cond_remarks"
                          :options="{ theme: 'snow' }"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="text-right">
                      <b-button
                        @click="goBack()"
                        type="button"
                        class="mr-1"
                        variant="outline-danger"
                      >
                        Back
                      </b-button>
                      <b-button
                        :disabled="invalid"
                        v-b-modal.tab-information
                        type="button"
                        variant="primary"
                      >
                        {{ onUpdate }}
                      </b-button>

                      <Modal
                        modalId="tab-information"
                        @onChange="(v) => onSubmit()"
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>

              <span v-if="formData.id">
                <b-tab title="Notes">
                  <Notes
                    ref="notesRef"
                    module="renewals"
                    :header-id="parseInt(formData.id)"
                  />
                </b-tab>
                <b-tab title="Documents">
                  <Documents
                    ref="documentsRef"
                    module="renewals"
                    :header-id="parseInt(formData.id)"
                  />
                </b-tab>
                <b-tab title="Comments"> </b-tab>
                <b-tab title="Logs"> </b-tab>
              </span>
            </b-tabs>
          </b-card>
        </validation-observer>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import jobRelocationApi from '@api/job_relocations';
import usersApi from '@api/users';
import statusesApi from '@api/statuses';
import uomsApi from '@api/uoms';
import { required } from '@validations';

export default {
  name: 'JobRelocationManage',
  components: {},
  data: () => ({
    errors: null,
    required,
    activeTab: 0,
    contentDescriptions: '',
    contentRemarks: '',
    contentConditionRemarks: '',
    previewStartDate: null,
    previewEndDate: null,
    previewScanStartDate: null,
    previewScanEndDate: null,
    previewScanReceived: null,
    formData: {
      id: 0,
      comp_id: null,
      refnum: null,
      descr: null,
      remarks: null,
      s_date: null,
      e_date: null,
      from_loc: null,
      to_loc: null,

      emp_id_1: 0,
      emp_id_2: 0,
      item_id: 0,
      qty: 0,
      uom: null,
      sr_no: null,
      barcode: null,
      cond_status: null,
      cond_remarks: null,
      emp_id: 0,
      scn_s_date: null,
      scn_e_date: null,
      scn_s_emp_id: 0,
      scn_e_emp_id: 0,
      scn_s_src: null,
      scn_e_src: null,
      scn_rcvd: null,
      scn_rcvd_emp_id: 0,
      scn_rcvd_src: null,
      m_status: null,

      company_name: null,
      item_name: null,
    },
    users: [],
    movementStatus: [],
    conditionStatus: [],
    uoms: [],
  }),
  mounted() {
    const [date] = this.defaultDateTime().split('T');
    this.previewStartDate = date;
    this.previewEndDate = date;

    this.loadUOMS();
    this.loadSystemCode();
    this.loadAvailableTechnicians();

    if (this.$route.query.id) {
      this.loadJobRelocation();
    }
  },
  watch: {
    activeTab(v) {
      if (v === 1) {
        this.$refs.notesRef.loadNotes();
      } else if (v === 2) {
        this.$refs.documentsRef.loadDocuments();
      }
    },
  },
  computed: {
    onUpdate: {
      get() {
        return this.$route.query.id ? 'Update' : 'Submit';
      },
    },
  },
  methods: {
    loadJobRelocation() {
      jobRelocationApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          const unixToDate = (date) => {
            if (date) {
              const [dateValue] = this.inputDateTimeFromUnix(date).split('T');

              if (dateValue) {
                return dateValue;
              }
            }
          };

          const {
            quillEditorRefDescriptions,
            quillEditorRefRemarks,
            quillEditorRefConditionRemarks,
          } = this.$refs;

          const { quill: qDescription } = quillEditorRefDescriptions;
          if (qDescription) {
            qDescription.root.innerHTML = data.descr;
          }

          const { quill: qRemarks } = quillEditorRefRemarks;
          if (qRemarks) {
            qRemarks.root.innerHTML = data.remarks;
          }

          const { quill: qCondRemarks } = quillEditorRefConditionRemarks;
          if (qCondRemarks) {
            qCondRemarks.root.innerHTML = data.cond_remarks;
          }

          this.previewStartDate = unixToDate(data.s_date);
          this.previewEndDate = unixToDate(data.e_date);
          this.previewScanStartDate = unixToDate(data.scn_s_date);
          this.previewScanEndDate = unixToDate(data.scn_e_date);
          this.previewScanReceived = unixToDate(data.scn_rcvd);

          this.formData = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadAvailableTechnicians() {
      const params = {
        sort: 'first_name',
        page: { size: 1000 },
        status: 'O',
        filterExt: `roles=user&coyuser&custuser&tech`,
      };

      usersApi
        .list(params)
        .then(({ data }) => {
          const items = [];

          for (let i = 0; i < data.length; i++) {
            const item = data[i];

            let fullName = item.first_name;
            if (item.last_name) {
              fullName += ` ${item.last_name}`;
            }

            items.push({ label: fullName, value: +item.id, item });
          }

          this.users = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadUOMS() {
      const params = {
        sort: '-created_at',
        page: { size: 1000 },
        status: 'O',
      };

      uomsApi
        .list(params)
        .then(({ data, meta }) => {
          if (data) {
            for (const item of data) {
              this.uoms.push({ text: item.name, value: item.name });
            }
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadSystemCode() {
      const params = {
        sort: '-created_at',
        page: { size: 1000 },
        status: 'O',
        filterExt:
          'code=job_relocation:movement:status|job_relocation:condition:status',
      };

      statusesApi
        .list(params)
        .then(({ data, meta }) => {
          const [ms] = data.filter((x) => {
            return x.code === 'job_relocation:movement:status';
          });
          if (ms) {
            this.movementStatus = ms.selections;
          }

          const [cs] = data.filter((x) => {
            return x.code === 'job_relocation:condition:status';
          });
          if (cs) {
            this.conditionStatus = cs.selections;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      this.$refs.formRef.validate().then((success) => {
        if (success) {
          let app;

          this.formData.descr = this.contentDescriptions;
          this.formData.remarks = this.contentRemarks;
          this.formData.cond_remarks = this.contentConditionRemarks;
          this.formData.s_date = this.inputDateTimeToUnix(
            this.previewStartDate
          );
          this.formData.e_date = this.inputDateTimeToUnix(this.previewEndDate);
          this.formData.scn_s_date = this.inputDateTimeToUnix(
            this.previewScanStartDate
          );
          this.formData.scn_e_date = this.inputDateTimeToUnix(
            this.previewScanEndDate
          );
          this.formData.scn_rcvd = this.inputDateTimeToUnix(
            this.previewScanReceived
          );

          if (this.formData.id) {
            app = jobRelocationApi.update(this.formData);
          } else {
            app = jobRelocationApi.add(this.formData);
          }

          if (app) {
            app
              .then(({ data }) => {
                this.toastConfig();
                const url = `/pages/services-tools/job-relocation/view?id=${data.id}`;
                this.$router.push(url);
              })
              .catch(() => {
                this.toastConfig('', 'error');
              })
              .finally(() => {
                //
              });
          }
        }
      });
    },
    onSelectCompany(event) {
      if (event) {
        const { id, company_name } = event;

        this.formData.comp_id = parseInt(id);
        this.formData.company_name = company_name;
      }
    },
    onSelectItem(event) {
      const {
        id,
        item_name,
        uom,
        serial_no,
        barcode,
        movement_status,
        condition_status,
        quantity,
      } = event;

      this.formData.item_id = +id;
      this.formData.item_name = item_name;
      this.formData.uom = uom;
      this.formData.sr_no = serial_no;
      this.formData.barcode = barcode;
      this.formData.m_status = movement_status;
      this.formData.cond_status = condition_status;
      this.formData.qty = quantity;
    },
    goBack() {
      this.$router.push('/pages/services-tools/job-relocation');
    },
  },
};
</script>

<style lang="scss"></style>
