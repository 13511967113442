<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>Manage Add/Edit Product</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" :md="openSection ? '9' : '12'">
        <b-card>
          <b-tabs v-model="tabIndex" fill>
            <b-tab title="Information" active>
              <b-row>
                <b-col>
                  <h2>Product Information</h2>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Name" label-for="v-name">
                    <b-form-input
                      disabled
                      v-model="formData.name"
                      id="v-name"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Category" label-for="v-category">
                    <v-select
                      disabled
                      id="v-category"
                      label="name"
                      v-model.number="formData.category_id"
                      :options="categories"
                      :reduce="(categories) => +categories.id"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Manufacturer" label-for="v-manufacturer">
                    <v-select
                      disabled
                      id="v-manufacturer"
                      label="name"
                      v-model.number="formData.manufacturer_id"
                      :options="manufacturers"
                      :reduce="(manufacturers) => +manufacturers.id"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="3">
                  <b-form-group label="SKU" label-for="v-sku">
                    <b-form-input disabled v-model="formData.sku" id="v-sku" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Barcode" label-for="v-barcode">
                    <b-form-input
                      disabled
                      v-model="formData.barcode"
                      id="v-barcode"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Price" label-for="v-price">
                    <cleave
                      disabled
                      id="v-price"
                      placeholder="0.00"
                      v-model="formData.preview_price"
                      class="form-control text-right"
                      :raw="false"
                      :options="{
                        numeral: true,
                        numeralThousandsGroupStyle: 'thousand',
                      }"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="New Price" label-for="v-new_price">
                    <cleave
                      disabled
                      id="v-new_price"
                      placeholder="0.00"
                      v-model="formData.preview_new_price"
                      class="form-control text-right"
                      :raw="false"
                      :options="{
                        numeral: true,
                        numeralThousandsGroupStyle: 'thousand',
                      }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="3">
                  <b-form-group label="UOM" label-for="v-uom">
                    <b-form-input disabled v-model="formData.uom" id="v-uom" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Brand" label-for="v-brand">
                    <v-select
                      disabled
                      id="v-brand"
                      label="name"
                      v-model="formData.brand"
                      :options="brands"
                      :reduce="(brands) => brands.name"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Model No." label-for="v-model">
                    <b-form-input
                      disabled
                      v-model="formData.model"
                      id="v-model"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group
                    label="Stock Quantity"
                    label-for="v-stock_quantity"
                  >
                    <b-form-input
                      disabled
                      type="number"
                      class="text-right"
                      v-model.number="formData.stock_quantity"
                      id="v-stock_quantity"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="4">
                  <b-form-group
                    label="No. Of Servicing"
                    label-for="v-no_of_servicing"
                  >
                    <b-form-input
                      disabled
                      type="number"
                      v-model.number="formData.no_of_servicing"
                      id="v-no_of_servicing"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group
                    label="Minimum Order Quantity"
                    label-for="v-min_order_quantity"
                  >
                    <b-form-input
                      disabled
                      type="number"
                      v-model.number="formData.min_order_quantity"
                      id="v-min_order_quantity"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group
                    label="Maximum Order Quantity"
                    label-for="v-max_order_quantity"
                  >
                    <b-form-input
                      disabled
                      type="number"
                      v-model.number="formData.max_order_quantity"
                      id="v-max_order_quantity"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Product Type" label-for="v-producttype">
                    <v-select
                      disabled
                      id="v-producttype"
                      label="name"
                      v-model.number="formData.product_type_id"
                      :options="productTypes"
                      :reduce="(productTypes) => +productTypes.id"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Video Embed Code Link" label-for="v-url">
                    <b-form-input disabled v-model="formData.url" id="v-url" />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <h2>Product Images</h2>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <ProductImages
                      isDisabled
                      :data-source="dataSourceProductImages"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <b-form-group label="Description" label-for="v-description">
                    <quill-editor
                      disabled
                      ref="quillEditorRef"
                      v-model="formData.description"
                      id="v-description"
                      :options="{ theme: 'snow' }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="text-right">
                  <b-button
                    type="button"
                    class="mr-1"
                    variant="outline-danger"
                    @click="$router.push(`/pages/catalogues/products`)"
                  >
                    Back
                  </b-button>
                  <b-button
                    v-b-modal.tab-information
                    type="button"
                    variant="success"
                  >
                    Edit
                  </b-button>

                  <Modal
                    modalId="tab-information"
                    @onChange="(v) => onEdit()"
                  />
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>

      <b-col cols="12" :md="openSection ? '3' : '12'" v-if="openSection">
        <b-card no-body>
          <b-tabs v-model="tabIndexSection" fill>
            <b-tab active>
              <template #title>
                <small>Comments</small>
              </template>
            </b-tab>
            <b-tab>
              <template #title>
                <small>Logs</small>
              </template>
            </b-tab>
            <b-tab>
              <template #title>
                <small>Approval</small>
              </template>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import productApi from '@api/products';
import categoriesApi from '@api/categories';
import manufacturersApi from '@api/manufacturers';
import brandsApi from '@api/brands';
import { getImage } from '@/global-functions';
import { queryParameters } from '@/schema';
import ProductImages from './components/ProductImages';

export default {
  name: 'ProductsView',
  components: {
    ProductImages,
  },
  data: () => ({
    tabIndex: 0,
    openSection: false,
    productTypes: [
      { id: 1, name: 'Active' },
      { id: 0, name: 'Out Of Stock' },
    ],
    categories: [],
    manufacturers: [],
    brands: [],
    dataSourceProductImages: [],
    content: '',

    formData: {},
  }),
  mounted() {
    this.loadCategories();
    this.loadManufacturer();
    this.loadBrands();

    if (+this.$route.query.id) {
      this.loadProduct();
    }
  },
  methods: {
    onEdit() {
      const url = `/pages/catalogues/products/manage?id=${this.$route.query.id}`;
      this.$router.push(url);
    },
    loadProduct() {
      productApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          data.preview_price = data.price.toFixed(2);
          data.preview_new_price = data.new_price.toFixed(2);

          if (data.product_images.data) {
            for (const image of data.product_images.data) {
              const getImageData = {
                model: 'products',
                id: data.id,
                thumbnail: image.thumbnail,
              };

              const item = {
                id: image.id,
                name: image.name,
                description: image.description,
                is_default: image.is_default,
                thumbnail: image.thumbnail,
                thumbnailb64: '',
                url: getImage(getImageData),
              };

              this.dataSourceProductImages.push(item);
            }
          }

          this.formData = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadCategories() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      categoriesApi
        .list(params)
        .then(({ data }) => {
          this.categories = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadManufacturer() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      manufacturersApi
        .list(params)
        .then(({ data }) => {
          this.manufacturers = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadBrands() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      brandsApi
        .list(params)
        .then(({ data }) => {
          this.brands = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style></style>
