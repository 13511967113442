export default [
  {
    path: '/pages/e-commerce/orders',
    name: 'orders',
    component: () => import('@/views/pages/e-commerce/orders/index'),
    meta: {
      resource: 'WebSalesOrders', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/e-commerce/orders/view',
    name: 'orders-view',
    component: () => import('@/views/pages/e-commerce/orders/OrderView'),
    meta: {
      resource: 'WebSalesOrders', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },

  {
    path: '/pages/e-commerce/promos',
    name: 'promos',
    component: () => import('@/views/pages/e-commerce/promos/index'),
    meta: {
      resource: 'Promos', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/e-commerce/promos/manage',
    name: 'promos-manage',
    component: () => import('@/views/pages/e-commerce/promos/manage'),
    meta: {
      resource: 'Promos', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
  {
    path: '/pages/e-commerce/promos/view',
    name: 'promos-view',
    component: () => import('@/views/pages/e-commerce/promos/view'),
    meta: {
      resource: 'Promos', // page name or all
      action: 'manage', // create, read, update, delete and manage
    },
  },
];
