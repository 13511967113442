<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Section Banners"
              url="/pages/banners/section-banners/manage"
              :data-source="dataSource"
              :total-count="totalCount"
              @on-delete="(v) => onDelete(v)"
              @on-update-cancel="(v) => loadHeaderBanners()"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
            >
              <template #columns>
                <DxColumn
                  v-for="(item, index) in dataColumns"
                  :key="index"
                  :data-field="item.field"
                  :caption="item.caption"
                  :cell-template="item.template"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import sectionBannersApi from '@api/section_banners';
import { queryParameters } from '@/schema';

export default {
  name: 'HeaderBannersPage',
  components: {},
  data: () => ({
    dataSource: [],
    dataColumns: [
      { caption: 'Section', field: 'preview' },
      { caption: 'Keyword', field: 'keyword' },
    ],
    totalCount: 0,
    page: 0,
    size: 0,
    keyword: '',
    sections: [
      { text: 'Featured', value: 'F' },
      { text: 'Event Hall', value: 'EH' },
      { text: 'Flash Sale', value: 'FS' },
      { text: 'Latest News', value: 'LN' },
      { text: 'Latest Products', value: 'LP' },
      { text: 'Top Rated', value: 'TR' },
      { text: 'Email Promotions', value: 'SO' },
      { text: 'Best Sellers', value: 'BS' },
      { text: 'Retail Partners', value: 'RP' },
    ],
  }),
  watch: {
    page(v) {
      this.loadHeaderBanners();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadHeaderBanners();
      }
    },
  },
  mounted() {
    this.loadHeaderBanners();
  },
  methods: {
    loadHeaderBanners() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      sectionBannersApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            for (const item of data) {
              const section = this.sections.find((x) => {
                return x.value === item.section;
              });

              item.preview = section.text;
            }

            this.dataSource = data;
            this.totalCount = meta.page.total;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onDelete(event) {
      sectionBannersApi
        .delete(event)
        .then(() => {
          this.loadHeaderBanners();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style></style>
