import api from '@apiInstance';

const endpoint = 'sales_order_headers';
export default {
  add(body) {
    return api.post(endpoint, body);
  },
  get(id) {
    return api.fetch(`${endpoint}/${id}`);
  },
  async list(params) {
    return await api.fetch(endpoint, { params });
  },
  update(body) {
    return api.patch(endpoint, body);
  },
  delete(id) {
    return api.remove(endpoint, id);
  },
  generatepdf(id) {
    return api.fetch(`${endpoint}/${id}/generatepdf`);
  },
};
