<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col :md="isTogglingView && toggledViewData ? '8' : '12'">
        <b-card>
          <b-card-text>
            <DxGrid
              title="Products"
              url="/pages/catalogues/products/manage"
              canSelect="none"
              :data-source="dataSource"
              :total-count="totalCount"
              @onToggleView="(v) => onToggleView(v)"
              @on-delete="(v) => onDelete(v)"
              @on-row-click="(v) => onViewDetail(v)"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
              @on-search="(v) => (keyword = v)"
              @on-status-change="(v) => (status = v)"
            >
              <template #columns>
                <DxColumn data-field="name" caption="Name " />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        :md="isTogglingView ? '4' : '12'"
        v-if="isTogglingView && toggledViewData"
      >
        <b-card>
          <b-card-text> </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import productsApi from '@api/products';
import { queryParameters } from '@/schema';

export default {
  name: 'ProductPage',
  components: {},
  data: () => ({
    isTogglingView: false,
    toggledViewData: null,
    dataSource: [],
    selectedData: [],
    totalCount: 0,
    page: 0,
    size: 0,
    keyword: null,
    status: 'O',
  }),
  watch: {
    page(v) {
      this.loadProducts();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadProducts();
      }
    },
    keyword(v) {
      this.loadProducts();
    },
    status(v) {
      this.loadProducts();
    },
  },
  mounted() {
    this.loadProducts();
  },
  methods: {
    loadProducts() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: this.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      productsApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.dataSource = data;
            this.totalCount = meta.page.total;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },

    onToggleView(event) {
      this.isTogglingView = event;

      if (!event) {
        this.toggledViewData = null;
      }
    },
    onViewDetail(event) {
      const data = event && event.row && event.row.data ? event.row.data : null;

      if (this.isTogglingView) {
        if (data) {
          this.toggledViewData = Object.assign({}, data);
        }

        return;
      }

      const url = `/pages/catalogues/products/view?id=${event.key}`;
      this.$router.push(url);
    },
    onUpdate(event) {
      const data = event.data;
      productsApi
        .update(data)
        .then(({ data }) => {
          if (data) {
            this.toastConfig();
          }
        })
        .catch((err) => {
          if (err) {
            this.toastConfig('', 'error');
          }
        })
        .finally(() => {
          //
        });
    },
    onDelete(event) {
      productsApi
        .delete(event)
        .then(() => {
          this.loadProducts();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style></style>
