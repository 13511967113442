<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col :md="isTogglingView && toggledViewData ? '8' : '12'">
        <b-card>
          <b-card-text>
            <DxGrid
              title="Sales Order"
              url="/pages/sales-tools/sales-order/manage"
              :data-source="dataSource"
              :total-count="totalCount"
              @onToggleView="(v) => onToggleView(v)"
              @on-delete="(v) => onDelete(v)"
              @on-update="(v) => onUpdate(v)"
              @on-update-cancel="(v) => loadSalesOrder()"
              @on-row-click="(v) => onViewDetail(v)"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
              @on-status-change="(v) => (status = v)"
              @on-search="(v) => (keyword = v)"
            >
              <template #columns>
                <DxColumn
                  v-for="(item, index) in dataColumns"
                  :key="index"
                  :data-field="item.field"
                  :caption="item.caption"
                />
              </template>

              <template #summaries>
                <DxSummary>
                  <DxGroupItem
                    column="id"
                    summary-type="count"
                    display-format="{0} orders"
                  />
                  <DxGroupItem
                    :show-in-group-footer="false"
                    :align-by-column="true"
                    column="total"
                    summary-type="max"
                    value-format="currency"
                  />
                  <DxGroupItem
                    :show-in-group-footer="true"
                    column="total"
                    summary-type="sum"
                    value-format="currency"
                    display-format="Total: {0}"
                  />
                </DxSummary>

                <DxSortByGroupSummaryInfo summary-item="count" />
              </template>
            </DxGrid>

            <div class="" v-if="selectedData.length > 0">
              <b-button
                v-b-modal.modal-center
                class="mr-1"
                variant="primary"
                size="sm"
                @click="actionStatus = 'print_batch'"
              >
                Print
              </b-button>
              <b-button
                v-b-modal.modal-center
                class="mr-1"
                variant="success"
                size="sm"
                @click="actionStatus = 'confirm'"
              >
                Confirm
              </b-button>
              <b-button
                v-b-modal.modal-center
                class="mr-1"
                variant="info"
                size="sm"
                @click="actionStatus = 'reopen'"
              >
                Reopen
              </b-button>
              <b-button
                v-b-modal.modal-center
                class="mr-1"
                variant="danger"
                size="sm"
                @click="actionStatus = 'void'"
              >
                Void
              </b-button>

              <Modal @onChange="(v) => handleEvent(actionStatus, {})" />
            </div>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        :md="isTogglingView ? '4' : '12'"
        v-if="isTogglingView && toggledViewData"
      >
        <b-card>
          <b-card-text> </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
  import salesOrderApi from '@api/sales_order_headers';
  import { queryParameters } from '@/schema';

  const update = (data) => {
    salesOrderApi
      .update(data)
      .then(() => {
        //
      })
      .catch(() => {
        //
      })
      .finally(() => {
        //
      });
  };

  export default {
    name: 'SalesOrderPage',
    components: {},
    data: () => ({
      keyword: '',
      isTogglingView: false,
      toggledViewData: null,
      dataSource: [],
      dataColumns: [
        { field: 'ticket_no', caption: 'Ticket No.' },
        { field: 'company_name', caption: 'Customer' },
        { field: 'display_date', caption: 'Transaction Date' },
        { field: 'display_total', caption: 'Total' },
      ],
      totalCount: 0,
      page: 1,
      size: 10,
      status: 'O',
      selectedData: [],
      actionStatus: '',
    }),
    watch: {
      page(v) {
        this.loadSalesOrder();
      },
      size(v) {
        if (v !== queryParameters.page.size) {
          this.loadSalesOrder();
        }
      },
      keyword(v) {
        this.loadSalesOrder();
      },
      status(v) {
        this.loadSalesOrder();
      },
    },
    mounted() {
      this.loadSalesOrder();
    },
    methods: {
      loadSalesOrder() {
        const params = {
          sort: '-updated_at',
          page: { size: this.size, after: this.page },
          status: this.status,
        };

        if (this.keyword) {
          params.filter = this.keyword;
        }

        salesOrderApi
          .list(params)
          .then(({ data, meta }) => {
            if (data != null) {
              for (const item of data) {
                const { transaction_date, total_amount } = item;

                item.display_date =
                  this.displayDateTimeFromUnix(transaction_date);
                item.display_total = this.moneyFormat(total_amount);
              }

              this.dataSource = data;
              this.totalCount = meta.page.total;
            }
          })
          .catch((err) => {
            //
          })
          .finally(() => {
            //
          });
      },
      handleEvent(action, event) {
        this.$nextTick(() => {
          if (action == 'confirm') {
            this.selectedData.forEach((data) => {
              data.status = 'C';
              update(data);
            });
          }

          if (action == 'reopen') {
            this.selectedData.forEach((data) => {
              data.status = 'R';
              update(data);
            });
          }

          if (action == 'void') {
            this.selectedData.forEach((data) => {
              data.status = 'V';
              update(data);
            });
          }

          if (action == 'print_batch') {
            const ids = this.selectedData.map((x) => {
              return +x.id;
            });

            const data = {
              id: 1,
              generated_ids: ids,
            };

            salesOrderApi
              .generatemultiplepdf(data)
              .then((res) => {
                if (res) {
                }
              })
              .catch(() => {
                //
              })
              .finally(() => {
                //
              });
          }
        });
      },
      onToggleView(event) {
        this.isTogglingView = event;

        if (!event) {
          this.toggledViewData = null;
        }
      },
      onViewDetail(event) {
        const { key: id } = event;

        const url = `/pages/sales-tools/sales-order/view?id=${id}`;
        this.$router.push(url);
      },
      onUpdate(event) {
        const data = event.data;
        salesOrderApi
          .update(data)
          .then(({ data }) => {
            if (data) {
              this.toastConfig();
            }
          })
          .catch((err) => {
            if (err) {
              this.toastConfig('', 'error');
            }
          })
          .finally(() => {
            //
          });
      },
      onDelete(event) {
        salesOrderApi
          .delete(event)
          .then(() => {
            this.loadSalesOrder();
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      },
      onPrintPDF(event) {
        const data = event?.row?.data;

        if (data) {
          salesOrderApi
            .generatepdf(data.id)
            .then((res) => {
              if (res) {
                this.openPdf(res);
              }
            })
            .catch(() => {
              //
            })
            .finally(() => {
              //
            });
        }
      },
    },
  };
</script>

<style></style>
