export default [
  // {
  //   header: "Servicing",
  // },
  {
    title: 'Servicing',
    icon: 'ToolIcon',
    children: [
      {
        title: 'Service Request',
        route: 'service-request',
        icon: 'ListIcon',
      },
      {
        title: 'Job Order',
        route: 'job-order',
        icon: 'ListIcon',
      },
      {
        title: 'Service Report',
        route: 'service-report',
        icon: 'ListIcon',
      },
      // {
      //   title: 'Job Relocation',
      //   route: 'job-relocation',
      //   icon: 'ListIcon',
      // },
    ],
  },
];
