<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col :md="isTogglingView && toggledViewData ? '8' : '12'">
        <b-card>
          <b-card-text>
            <DxGrid
              title="Job Orders"
              url="/pages/services-tools/job-order/manage"
              toggleView
              toggleQuickEdit
              :isEditable="getRole"
              :canAdd="getRole"
              :data-source="dataSource"
              :total-count="totalCount"
              @onToggleView="(v) => onToggleView(v)"
              @on-delete="(v) => onDelete(v)"
              @on-update="(v) => onUpdate(v)"
              @on-update-cancel="(v) => loadJobOrders()"
              @on-row-click="(v) => onViewDetail(v)"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
              @on-search="(v) => (keyword = v)"
            >
              <template #columns>
                <DxColumn
                  v-for="(item, index) in dataColumns"
                  :key="index"
                  :data-field="item.field"
                  :caption="item.caption"
                  :format="item.format"
                  :data-type="item.dataType"
                />
              </template>

              <template #action-buttons>
                <DxButton icon="clock" hint="OT Claim" :onClick="onOpenModal" />
                <DxButton icon="copy" hint="Duplicate" :onClick="onDuplicate" />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        :md="isTogglingView ? '4' : '12'"
        v-if="isTogglingView && toggledViewData"
      >
        <b-card>
          <b-card-text>
            <ViewJobOrderDetail
              v-if="isTogglingView"
              :job-order-data="toggledViewData"
            />
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal scrollable v-model="showModal" centered size="lg">
      <b-row>
        <b-col>
          <b-form-group label="Claim Reference" label-for="v-refnum">
            <b-form-input
              id="v-refnum"
              disabled
              v-model="formData.refnum"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Claim Date" label-for="v-preview_claim_date">
            <b-form-input
              id="v-preview_claim_date"
              type="datetime-local"
              v-model="formData.preview_claim_date"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Category" label-for="v-category">
            <b-form-select
              id="v-category"
              v-model="formData.category"
              :options="categories"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Title" label-for="v-title">
            <b-form-input id="v-title" v-model="formData.title"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Claim Remarks" label-for="v-claim_remarks">
            <b-form-textarea
              id="v-claim_remarks"
              v-model="formData.claim_remarks"
              rows="2"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h2>Job Information</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Job Order" label-for="v-joborder">
            <b-form-input
              id="v-joborder"
              disabled
              v-model="formData.joborder"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Job Type" label-for="v-job_type">
            <b-form-input
              id="v-job_type"
              disabled
              v-model="formData.job_type"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Service Request No." label-for="v-jobrequest">
            <b-form-input
              id="v-jobrequest"
              disabled
              v-model="formData.jobrequest"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Description" label-for="v-description">
            <quill-editor
              disabled
              :style="{
                height: onGetDescriptionLength(formData.description)
                  ? '200px'
                  : 'auto',
              }"
              ref="quillEditorRef"
              v-model="formData.description"
              id="v-description"
              :options="{ theme: 'bubble' }"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="From" label-for="v-preview_date_from">
            <b-form-input
              id="v-preview_date_from"
              type="datetime-local"
              v-model="formData.preview_date_from"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="To" label-for="v-preview_date_to">
            <b-form-input
              id="v-preview_date_to"
              type="datetime-local"
              v-model="formData.preview_date_to"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Claim Hours" label-for="v-claim_hours">
            <b-form-input
              id="v-claim_hours"
              v-model.number="formData.claim_hours"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Actual Claim" label-for="v-actual_claim">
            <b-form-input
              id="v-actual_claim"
              v-model.number="formData.actual_claim"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Claim Type" label-for="v-ot_type">
            <b-form-select
              id="v-ot_type"
              v-model="formData.ot_type"
              :options="otTypes"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <hr />
      <b-row>
        <b-col>
          <h2>For Official Use</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Claim Status" label-for="v-claim_status">
            <b-form-select
              id="v-claim_status"
              v-model="formData.claim_status"
              :options="claimStatuses"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Remarks" label-for="v-remarks">
            <b-form-textarea
              id="v-remarks"
              v-model="formData.remarks"
              rows="2"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Processed By" label-for="v-processed_by">
            <b-form-input
              id="v-processed_by"
              disabled
              v-model="formData.processed_by_user"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Processed Date"
            label-for="v-preview_processed_date"
          >
            <b-form-input
              id="v-preview_processed_date"
              disabled
              v-model="formData.preview_processed_date"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer="{ close }">
        <b-button @click="onCloseModal()" variant="danger" class="bg-darken-4">
          Cancel
        </b-button>
        <!-- <b-button @click="onVerify(close)" variant="info" class="bg-darken-4">
          Verify
        </b-button> -->
        <b-button
          @click="onSubmit(close)"
          variant="primary"
          class="bg-darken-4"
        >
          Submit
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModalDuplicate" centered no-close-on-backdrop>
      <b-row>
        <b-col>
          <b-form-group
            label="Primary/Secondary Technician"
            label-for="v-technician"
          >
            <b-form-select
              id="v-techinician"
              v-model="formDataDuplicate.technician"
              :options="[
                { text: 'Primary Techinician', value: 'primary' },
                { text: 'Secondary Techinician', value: 'secondary' },
              ]"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Technician" label-for="v-assigned_to">
            <v-select
              id="v-assigned_to"
              v-model="formDataDuplicate.assigned_to"
              :options="employeeItems"
              :reduce="(employeeItems) => employeeItems.value"
            >
              <template #option="{ label }">
                {{ label }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer="{ close }">
        <b-button @click="onCloseModal()" variant="danger" class="bg-darken-4">
          Cancel
        </b-button>
        <b-button
          @click="onSubmitDuplicate(close)"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import ViewJobOrderDetail from './QuickView.vue';
import jobOrderApi from '@api/joborder_headers';
import claimsApi from '@api/claims';
import statusesApi from '@api/statuses';
import usersApi from '@api/users';
// @todo change from utils classes to global-function
import { queryParameters } from '@/schema';
import { getUserData } from '@/auth/utils';

export default {
  name: 'JobOrderPage',
  components: {
    ViewJobOrderDetail,
  },
  data: () => ({
    showModal: false,
    isTogglingView: false,
    toggledViewData: null,
    showModalDuplicate: false,
    formData: {
      claim_date: null,
      claim_remarks: null,
      category: null,
      title: null,
      remarks: null,
      joborder_id: 0,
      job_type: null,
      jobrequest_id: 0,
      date_from: null,
      date_to: null,
      claim_hours: 0,
      actual_claim: 0,
      ot_type: null,
      process_by_user: null,
      verified_by_user: null,
      verified_date: null,
      claim_status: null,

      preview_date_from: null,
      preview_date_to: null,
      preview_verified_date: null,
      preview_processed_date: null,
      preview_claim_date: null,
    },
    formDataDuplicate: {
      from_id: 0,
      technician: null,
      assigned_to: 0,
      job_assigned_to_1: 0,
      job_assigned_to_2: 0,
    },
    dataSource: [],
    dataColumns: [
      { caption: 'Job Number', field: 'ticket_no' },
      { caption: 'Customer', field: 'company' },
      { caption: 'Job Type', field: 'job_type' },
      { caption: 'Assigned Primary', field: 'preview_employee_one' },
      { caption: 'Assigned Secondary', field: 'preview_employee_two' },
      { caption: 'Description', field: 'description' },
      {
        caption: 'Report Date',
        field: 'preview_report_date',
        dataType: 'datetime',
        format: 'dd/MM/yyyy hh:mm a',
      },
      {
        caption: 'Scheduled Start',
        field: 'preview_job_eta',
        dataType: 'datetime',
        format: 'dd/MM/yyyy hh:mm a',
      },
      {
        caption: 'Scheduled End',
        field: 'preview_job_etd',
        dataType: 'datetime',
        format: 'dd/MM/yyyy hh:mm a',
      },
      { caption: 'Job Status', field: 'preview_job_status' },
    ],
    otTypes: [],
    claimStatuses: [],
    categories: [],
    employeeItems: [],
    totalCount: 0,
    page: 0,
    size: 0,
    keyword: null,
  }),
  computed: {
    getRole: {
      get() {
        const userData = getUserData();
        const { role } = userData;

        return role === 'admin';
      },
    },
  },
  watch: {
    page(v) {
      this.loadJobOrders();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadJobOrders();
      }
    },
    keyword(v) {
      this.loadJobOrders();
    },
  },
  mounted() {
    this.loadJobOrders();
  },
  methods: {
    loadJobOrders() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
        filterExt: `job_status=0|1|2|3|4`,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      const userData = getUserData();
      const { companyId } = userData;

      if (companyId) {
        params.filterExt = `company_id=${companyId}`;
      }

      if (this.keyword) {
        params.filter = this.keyword;
      }

      jobOrderApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            for (const e of data) {
              const {
                company_name,
                problem_description,
                report_date,
                job_eta,
                job_etd,
                job_status,
                joborder_employee_one,
                joborder_employee_two,
              } = e;

              if (problem_description) {
                e.description = `${problem_description.substring(0, 15)}...`;
              }

              if (company_name) {
                e.company = `${company_name.substring(0, 15)}...`;
              }

              e.preview_report_date = new Date(report_date * 1000);
              e.preview_job_eta = new Date(job_eta * 1000);
              e.preview_job_etd = new Date(job_etd * 1000);
              e.preview_job_status = this.getJobStatus(job_status);

              if (joborder_employee_one) {
                const { data } = joborder_employee_one;

                if (data) {
                  e.preview_employee_one = data.display_name;
                }
              }

              if (joborder_employee_two) {
                const { data } = joborder_employee_two;

                if (data) {
                  e.preview_employee_two = data.display_name;
                }
              }
            }

            this.dataSource = data;
            this.totalCount = meta.page.total;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadSystemCode() {
      const params = {
        sort: '-created_at',
        page: { size: 1000 },
        status: 'O',
        filterExt: 'code=claims:ottypes|claims:status|claims:categories',
      };

      statusesApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            const [ottypes] = data.filter((x) => {
              return x.code === 'claims:ottypes';
            });
            if (ottypes) {
              this.otTypes = ottypes.selections;
            }

            const [status] = data.filter((x) => {
              return x.code === 'claims:status';
            });
            if (status) {
              this.claimStatuses = status.selections;
            }

            const [categories] = data.filter((x) => {
              return x.code === 'claims:categories';
            });
            if (categories) {
              this.categories = categories.selections;
            }
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadClaim(id) {
      const params = {
        status: 'O',
        filterExt: `joborder_id=${id}`,
      };

      claimsApi
        .list(params)
        .then(({ data }) => {
          const [item] = data;

          const {
            id,
            refnum,
            claim_date,
            claim_hours,
            category,
            title,
            claim_remarks,
            actual_claim,
            ot_type,
            verified_by_user,
            verified_date,
            processed_by_user,
            processed_date,
            claim_status,
            active,
            status,
            flag,
            uuid,
            created_at,
            remarks,
            date_from,
            date_to,
          } = item;

          this.formData.reference_id = +id;
          this.formData.refnum = refnum;
          this.formData.preview_claim_date =
            this.inputDateTimeFromUnix(claim_date);
          this.formData.category = category;
          this.formData.title = title;
          this.formData.claim_remarks = claim_remarks;
          this.formData.claim_hours = claim_hours;
          this.formData.actual_claim = actual_claim;
          this.formData.ot_type = ot_type;
          this.formData.verified_by_user = verified_by_user;
          this.formData.preview_verified_date =
            this.displayDateTimeFromUnix(verified_date);
          this.formData.processed_by_user = processed_by_user;
          this.formData.preview_processed_date =
            this.displayDateTimeFromUnix(processed_date);
          this.formData.claim_status = claim_status;
          this.formData.active = active;
          this.formData.status = status;
          this.formData.flag = flag;
          this.formData.uuid = uuid;
          this.formData.created_at = created_at;
          this.formData.remarks = remarks;

          if (date_from) {
            this.formData.preview_date_from =
              this.inputDateTimeFromUnix(date_from);
          }

          if (date_to) {
            this.formData.preview_date_to = this.inputDateTimeFromUnix(date_to);
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadAvailableTechnicians() {
      this.employeeItems = [{ label: 'N/A', value: 0 }];
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
        filterExt: `roles=user&coyuser&custuser&tech`,
      };

      const userData = getUserData();
      const { companyId } = userData;

      if (companyId) {
        params.filterExt += `,company_id=${companyId}`;
      }

      usersApi
        .list(params)
        .then(({ data }) => {
          const items = [];

          for (let i = 0; i < data.length; i++) {
            const item = data[i];

            let fullName = item.first_name;
            if (item.last_name) {
              fullName += ` ${item.last_name}`;
            }

            items.push({ label: fullName, value: +item.id, item });
          }

          this.employeeItems = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onToggleView(event) {
      this.isTogglingView = event;

      if (!event) {
        this.toggledViewData = null;
      }
    },
    onViewDetail(event) {
      const url = `/pages/services-tools/job-order/view?id=${event.key}`;
      this.$router.push(url);
    },
    onUpdate(event) {
      const data = event.data;
    },
    onDelete(event) {
      //
    },
    onSubmit(close) {
      const toUnix = (date) => {
        if (date) {
          return this.inputDateTimeToUnix(date);
        }
      };

      this.formData.date_from = toUnix(this.formData.preview_date_from);
      this.formData.date_to = toUnix(this.formData.preview_date_to);
      this.formData.claim_date = toUnix(this.formData.preview_claim_date);

      claimsApi
        .add(this.formData)
        .then(() => {
          this.toastConfig('OT Claim submitted');
          this.onCloseModal();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmitDuplicate(close) {
      close();

      const { assigned_to, technician } = this.formDataDuplicate;
      if (technician === 'primary') {
        this.formDataDuplicate.job_assigned_to_1 = assigned_to;
      } else {
        this.formDataDuplicate.job_assigned_to_2 = assigned_to;
      }

      jobOrderApi
        .duplicate(this.formDataDuplicate)
        .then(({ data }) => {
          if (data) {
            const url = `/pages/services-tools/job-order/view?id=${data.id}`;
            this.$router.push(url);
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onVerify(close) {
      const toUnix = (date) => {
        if (date) {
          return this.inputDateTimeToUnix(date);
        }
      };

      this.formData.date_from = toUnix(this.formData.preview_date_from);
      this.formData.date_to = toUnix(this.formData.preview_date_to);

      claimsApi
        .verify(this.formData)
        .then(() => {
          this.toastConfig('OT Claim verified');
          this.onCloseModal();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onDuplicate(event) {
      this.showModalDuplicate = true;
      this.loadAvailableTechnicians();
      const { key: from_id } = event.row;
      this.formDataDuplicate = {
        from_id,
      };
    },
    onOpenModal(event) {
      this.clearForm();
      this.loadSystemCode();
      this.showModal = true;

      const { data } = event.row;
      const {
        id,
        ticket_no,
        job_type,
        ticket_parent_no,
        job_ata,
        job_atd,
        job_assigned_to_1,
        job_instructions,
      } = data;

      this.loadClaim(id);

      this.formData.joborder_id = +id;
      this.formData.joborder = ticket_no;
      this.formData.job_type = job_type;
      this.formData.description = job_instructions;
      this.formData.jobrequest = ticket_parent_no;
      this.formData.preview_date_from = this.inputDateTimeFromUnix(job_ata);
      this.formData.preview_date_to = this.inputDateTimeFromUnix(job_atd);
      this.formData.assigned_user = job_assigned_to_1;
    },
    onCloseModal() {
      this.showModalDuplicate = false;
      this.showModal = false;
      this.clearForm();
    },
    onGetDescriptionLength(description) {
      if (description) {
        return description.length > 150;
      }
    },
    clearForm() {
      const defaults = {
        claim_date: null,
        claim_remarks: null,
        category: null,
        title: null,
        remarks: null,
        joborder_id: 0,
        job_type: null,
        jobrequest_id: 0,
        date_from: null,
        date_to: null,
        claim_hours: 0,
        actual_claim: 0,
        ot_type: null,
        process_by_user: null,
        verified_by_user: null,
        verified_date: null,
        claim_status: null,

        preview_date_from: null,
        preview_date_to: null,
        preview_verified_date: null,
        preview_processed_date: null,
        preview_claim_date: null,
      };

      this.formData = Object.assign({}, defaults);
    },
  },
};
</script>

<style></style>
