<template>
  <span>
    <b-row>
      <b-col>
        <DxDataGrid
          key-expr="id"
          :data-source="dataSource"
          :focused-row-enabled="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :row-alternation-enabled="true"
          @init-new-row="handleEvent('init-new-row', $event)"
          @option-changed="handleEvent('option-changed', $event)"
          @row-removed="handleEvent('row-removed', $event)"
        >
          <DxEditing
            :allow-updating="!isDisabled"
            :allow-deleting="!isDisabled"
            :allow-adding="!isDisabled"
            :use-icons="!isDisabled"
            mode="row"
          />

          <DxColumn v-if="!isDisabled" type="buttons">
            <DxButton icon="edit" :onClick="editItem" />

            <DxButton name="delete" />
          </DxColumn>

          <DxColumnChooser :enabled="true" />
          <DxColumnFixing :enabled="true" />
          <DxSorting mode="multiple" />
          <DxFilterRow :visible="true" />
          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="false" />
          <DxHeaderFilter :visible="true" />
          <DxSearchPanel location="before" :visible="true" />

          <DxColumn data-field="product_id" caption="Product">
            <DxLookup
              :data-source="productItems"
              value-expr="id"
              display-expr="name"
            />
          </DxColumn>
          <DxColumn
            disabled
            data-field="discount_percent"
            caption="Discount %"
            cell-template="cellDiscountPercentTemplate"
          />
          <DxColumn
            disabled
            data-field="discount_amount"
            caption="Discount Amount"
            cell-template="cellDiscountAmountTemplate"
          />

          <DxColumn data-field="promo_item" caption="Promo Item">
            <DxLookup
              :data-source="productItems"
              value-expr="id"
              display-expr="name"
            />
          </DxColumn>

          <DxColumn data-field="quantity" caption="Quantity" />
          <DxColumn
            disabled
            data-field="price"
            caption="Price"
            cell-template="cellPriceTemplate"
          />

          <template #cellPriceTemplate="{ data }">
            {{ moneyFormat(data.value) }}
          </template>
          <template #cellGrossAmountTemplate="{ data }">
            {{ moneyFormat(data.value) }}
          </template>
          <template #cellDiscountPercentTemplate="{ data }">
            {{ `${data.value}%` }}
          </template>
          <template #cellDiscountAmountTemplate="{ data }">
            {{ moneyFormat(data.value) }}
          </template>
          <template #cellTotalTemplate="{ data }">
            {{ moneyFormat(data.value) }}
          </template>

          <DxToolbar>
            <DxItem location="before" name="columnChooserButton" />
            <DxItem location="before" name="searchPanel" />
            <DxItem name="groupPanel" />

            <DxItem v-if="!isDisabled" template="addButtonTemplate" />
          </DxToolbar>

          <DxSummary>
            <DxGroupItem
              column="id"
              summary-type="count"
              display-format="{0} orders"
            />
            <DxGroupItem
              :show-in-group-footer="false"
              :align-by-column="true"
              column="total"
              summary-type="sum"
              value-format="currency"
            />
            <DxGroupItem
              :show-in-group-footer="true"
              column="total"
              summary-type="sum"
              value-format="currency"
              display-format="Total: {0}"
            />
          </DxSummary>

          <DxSortByGroupSummaryInfo summary-item="count" />

          <template #addButtonTemplate>
            <div>
              <b-button
                variant="primary"
                class="text-capitalize"
                @click="addModal = true"
              >
                Add
              </b-button>
            </div>
          </template>
        </DxDataGrid>
      </b-col>
    </b-row>

    <b-modal
      v-model="addModal"
      centered
      size="lg"
      title="Add Product"
      @hide="closeModal()"
    >
      <b-row>
        <b-col class="text-right">
          <ProductSearch @onSelect="(v) => selectProduct(v)" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Product Name" label-for="v-name">
            <b-form-input disabled v-model="formDetail.name" id="v-name" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Discount Percent" label-for="v-discount_percent">
            <b-form-input
              @input="onInput"
              type="number"
              id="v-discount_percent"
              name="discount_percent"
              class="form-control text-right"
              max="100"
              min="0"
              v-model="percentValue"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Discount Amount" label-for="v-discount_amount">
            <cleave
              id="v-discount_amount"
              name="discount_amount"
              v-model="formDetail.discount_amount"
              class="form-control text-right"
              :raw="false"
              :options="options.number"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="text-right">
          <ProductSearch @onSelect="(v) => selectPromoItem(v)" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Promo Item" label-for="v-promo_name">
            <b-form-input
              disabled
              v-model="formDetail.promo_name"
              id="v-promo_name"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Quantity" label-for="v-quantity">
            <cleave
              id="v-quantity"
              name="quantity"
              v-model="watchQty"
              class="form-control text-right"
              :raw="false"
              :options="options.number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Price" label-for="v-price">
            <cleave
              disabled
              id="v-price"
              name="price"
              v-model="watchPrice"
              class="form-control text-right"
              :raw="false"
              :options="options.number"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button @click="closeModal()" variant="danger" class="bg-darken-4">
          Cancel
        </b-button>
        <b-button @click="onSubmit" variant="primary" class="bg-darken-4">
          Save
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
  import productsApi from '@api/products';
  import { moneyFormat as mf } from '@/global-functions';

  import Cleave from 'vue-cleave-component';
  // eslint-disable-next-line import/no-extraneous-dependencies
  import 'cleave.js/dist/addons/cleave-phone.us';

  export default {
    name: 'PromoDetailsComponent',
    props: {
      dataSource: {
        type: Array,
        default: [],
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      formData: {
        type: Object,
        default: () => {},
      },
    },
    components: {
      Cleave,
    },
    watch: {
      dataSource(v) {
        //
      },
      watchQty(v) {
        const formDetail = this.formDetail;
        if (v) {
          const value = typeof v == 'string' ? v.replace(/,/g, '') : 0;
          if (value) {
            formDetail.quantity = +value;
            formDetail.gross_amount = formDetail.quantity * formDetail.price;
            this.watchGrossAmount = formDetail.quantity * formDetail.price;

            const discountPercent = +formDetail.discount_percent;

            if (!+discountPercent) {
              this.watchTotal = formDetail.total;
            }

            this.watchDiscountPercent = 0;
            this.watchDiscountAmount = 0;
          }
        }
      },
    },
    computed: {
      computedSummaryTotal: {
        get() {
          const value = this.dataSource.reduce((a, b) => a + b.total, 0);
          return value;
        },
      },
      computedSummaryGST: {
        get() {
          const value = this.dataSource.reduce((a, b) => a + b.total, 0);
          const gst = this.dataSource.gst || this.formData.gst_percent;
          const wgst = value * (gst / 100);
          return wgst || 0;
        },
      },
      computedSummaryFinal: {
        get() {
          const value = this.dataSource.reduce((a, b) => a + b.total, 0);
          const gst = this.dataSource.gst || this.formData.gst_percent;
          const wgst = value * (gst / 100);
          return value + wgst || 0;
        },
      },
    },
    data: () => ({
      dataIndex: -1,
      dataItems: [],
      addModal: false,
      formDetail: {
        id: 0,
        header_id: 0,
        product_id: 0,
        promo_item: 0,
        quantity: 1,
        price: 0,
        discount_percent: 0,
        discount_amount: 0,
        status: 'O',
      },

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 2,
          rawValueTrimPrefix: true,
          onValueChanged: (e) => {
            if (+e.target.rawValue) {
              //
            }
          },
        },
      },

      percentValue: '0',
      productItems: [],
      watchQty: '1',
      watchPrice: 0,
      watchGrossAmount: 0,
      watchTotal: 0,
    }),
    mounted() {
      this.loadProducts();
    },
    methods: {
      closeModal() {
        this.addModal = false;
        const defaultForm = {
          id: 0,
          product_id: 0,
          promo_item: 0,
          quantity: 1,
          price: 0,
          discount_percent: 0,
          discount_amount: 0,
          status: 'O',
        };

        this.$nextTick(() => {
          this.dataIndex = -1;
          this.formDetail = Object.assign({}, defaultForm);

          this.watchQty = 1;
          this.watchPrice = 0;
        });
      },
      loadProducts() {
        const params = {
          sort: '-updated_at',
          page: { size: 10000 },
          status: 'O',
        };

        productsApi
          .list(params)
          .then(({ data }) => {
            var items = [];
            data.forEach((e) => {
              const item = {
                id: +e.id,
                name: e.name,
              };

              items.push(item);
            });

            this.productItems = items;
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      },
      handleEvent(action, event) {
        //
      },
      onReorder(e) {
        const visibleRows = e.component.getVisibleRows();
        const toIndex = this.dataSource.indexOf(visibleRows[e.toIndex].data);
        const fromIndex = this.dataSource.indexOf(e.itemData);
        const newDataSource = [...this.dataSource];

        newDataSource.splice(fromIndex, 1);
        newDataSource.splice(toIndex, 0, e.itemData);

        this.dataSource = newDataSource;
      },
      editItem(event) {
        if (event) {
          this.addModal = true;
          this.$nextTick(() => {
            const data = event.row.data;
            const product = this.productItems.find((x) => {
              return x.id == data.product_id;
            });

            const promo = this.productItems.find((x) => {
              return x.id == data.promo_item;
            });

            data.name = product ? product.name : '';
            data.promo_name = promo ? promo.name : '';
            this.dataIndex = event.row.dataIndex;
            this.formDetail = Object.assign({}, data);
            this.percentValue = data.discount_percent;

            this.watchPrice = data.price;
            this.watchQty = data.quantity;
          });
        }
      },
      previewItem(event) {
        //
      },
      moneyFormat(data) {
        return mf(data);
      },
      selectProduct(data) {
        if (data) {
          this.loadProducts();

          const dataSource = this.dataSource[this.dataIndex];
          const dataIndex = this.dataIndex > -1;
          const item = {
            id: dataIndex ? dataSource.id : this.dataSource.length + 1,
            product_id: +data.id,
            name: data.name,
            discount_percent: dataIndex ? dataSource.discount_percent : 0,
            discount_amount: dataIndex ? dataSource.discount_amount : 0,
            status: 'O',
          };

          this.watchQty = '1';

          this.formDetail = Object.assign({}, item);
        }
      },
      selectPromoItem(data) {
        if (data) {
          this.loadProducts();

          const dataSource = this.dataSource[this.dataIndex];
          const dataIndex = this.dataIndex > -1;
          const item = {
            promo_item: +data.id,
            promo_name: data.name,
            quantity: dataIndex ? String(dataSource.quantity) : '1',
            price: +data.price,
            status: 'O',
          };

          this.watchPrice = data.price;
          this.watchQty = item.quantity;

          this.formDetail = Object.assign(this.formDetail, item);
        }
      },
      onSubmit() {
        const { quantity: q, discount_amount: da } = this.formDetail;

        this.formDetail.quantity = +q || 0;
        this.formDetail.discount_amount = +da.replaceAll(',', '') || 0;

        if (this.dataIndex > -1) {
          this.dataSource.splice(this.dataIndex, 1);
          this.dataSource.splice(this.dataIndex, 0, this.formDetail);
        } else {
          this.dataSource.push(this.formDetail);
        }

        this.closeModal();
      },
      onInput(event) {
        if (+event > 100) {
          this.percentValue = '0';
          this.formDetail.discount_percent = '0';
        }

        this.formDetail.discount_percent = +event;
      },
    },
  };
</script>

<style></style>
