<template>
  <span>
    <DxDataGrid
      key-expr="id"
      :data-source="dataSource"
      :focused-row-enabled="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-borders="true"
      :row-alternation-enabled="true"
      @init-new-row="handleEvent('init-new-row', $event)"
      @option-changed="handleEvent('option-changed', $event)"
      @row-removed="handleEvent('row-removed', $event)"
    >
      <DxEditing
        :allow-updating="!isDisabled"
        :allow-deleting="!isDisabled"
        :allow-adding="!isDisabled"
        :use-icons="!isDisabled"
        mode="row"
      />

      <DxColumn v-if="!isDisabled" type="buttons">
        <DxButton icon="edit" :onClick="editItem" />
        <DxButton name="delete" />
      </DxColumn>

      <DxColumn data-field="name" caption="Name" />
      <DxColumn data-field="serial_no" caption="Serial No." />
      <DxColumn data-field="brand" caption="Brand ID" />
      <DxColumn data-field="model" caption="Model ID" />

      <DxColumnChooser :enabled="true" />
      <DxColumnFixing :enabled="true" />
      <DxSorting mode="multiple" />
      <DxFilterRow :visible="true" />
      <DxGroupPanel :visible="true" />
      <DxGrouping :auto-expand-all="false" />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel location="before" :visible="true" />

      <DxToolbar>
        <DxItem location="before" name="columnChooserButton" />
        <DxItem location="before" name="searchPanel" />
        <DxItem name="groupPanel" />

        <DxItem v-if="!isDisabled" template="addButtonTemplate" />
      </DxToolbar>

      <template #addButtonTemplate>
        <div>
          <b-button
            variant="primary"
            class="text-capitalize"
            @click="addModal = true"
          >
            Add
          </b-button>
        </div>
      </template>
    </DxDataGrid>

    <b-modal
      v-model="addModal"
      centered
      size="lg"
      title="Add Product"
      @hide="closeModal()"
    >
      <b-row>
        <b-col class="text-right">
          <ProductSearch @onSelect="(v) => selectProduct(v)" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Product Name" label-for="v-name">
            <b-form-input disabled v-model="formData.name" id="v-name" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Serial No." label-for="v-serial_no">
            <b-form-input v-model="formData.serial_no" id="v-serial_no" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Brand" label-for="v-brand">
            <b-form-input v-model="formData.brand" id="v-brand" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Model" label-for="v-model">
            <b-form-input v-model="formData.model" id="v-model" />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button @click="closeModal()" variant="danger" class="bg-darken-4">
          Cancel
        </b-button>
        <b-button @click="onSubmit" variant="primary" class="bg-darken-4">
          Save
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import productsApi from '@api/products';
import { queryParameters } from '@/schema';

export default {
  name: 'ServiceRequestDetailsComponents',
  props: {
    dataSource: {
      type: Array,
      default: [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dataSource(items) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (item.product && item.product.data) {
          item.name = item.product.data.name;
        }
      }
    },
  },
  data: () => ({
    dataIndex: -1,
    productItems: [],
    addModal: false,
    formData: {
      id: 0,
    },
  }),
  mounted() {
    this.loadProducts();
  },
  methods: {
    closeModal() {
      this.addModal = false;
      const defaultForm = {
        id: 0,
        header_id: 0,
        status: 'O',
      };

      this.$nextTick(() => {
        this.dataIndex = -1;
        this.formData = Object.assign({}, defaultForm);
      });
    },
    loadProducts() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      productsApi
        .list(params)
        .then(({ data }) => {
          this.productItems = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    handleEvent(action, event) {
      if (action == 'row-removed') {
        //
      }
    },
    onReorder(e) {
      const visibleRows = e.component.getVisibleRows();
      const toIndex = this.dataSource.indexOf(visibleRows[e.toIndex].data);
      const fromIndex = this.dataSource.indexOf(e.itemData);
      const newDataSource = [...this.dataSource];

      newDataSource.splice(fromIndex, 1);
      newDataSource.splice(toIndex, 0, e.itemData);

      this.dataSource = newDataSource;
    },
    editItem(event) {
      if (event) {
        this.addModal = true;
        this.$nextTick(() => {
          const data = event.row.data;

          this.dataIndex = event.row.dataIndex;
          this.formData = Object.assign({}, data);
        });
      }
    },
    onSubmit() {
      if (this.dataIndex > -1) {
        this.dataSource.splice(this.dataIndex, 1);
        this.dataSource.splice(this.dataIndex, 0, this.formData);
      } else {
        this.dataSource.push(this.formData);
      }

      this.closeModal();
    },
    selectProduct(item) {
      this.formData.header_id = parseInt(item.id);
      this.formData.name = item.name;
      this.formData.serial_no = item.sku;
      this.formData.brand = item.brand;
      this.formData.model = item.model;

      this.formData = Object.assign({}, this.formData);
    },
  },
};
</script>

<style></style>
