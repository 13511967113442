<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Promos"
              url="/pages/e-commerce/promos/manage"
              :data-source="dataSource"
              :total-count="totalCount"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onManage(v)"
              @on-update-cancel="(v) => loadPromos()"
              @on-row-click="(v) => onViewDetail(v)"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
              @on-search="(v) => (keyword = v)"
              @on-status-change="(v) => (status = v)"
            >
              <template #columns>
                <DxColumn
                  v-for="(item, index) in dataColumns"
                  :key="index"
                  :data-field="item.field"
                  :caption="item.caption"
                  :format="item.format"
                  :data-type="item.dataType"
                  :encode-html="item.encodeHtml"
                  :css-class="item.cssClass"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="onShowModal"
      centered
      @hide="onCloseModal()"
      size="lg"
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <b-form-group label="Name" label-for="v-name">
            <b-form-input v-model="formData.module" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Description" label-for="v-description">
            <b-form-textarea v-model="formData.description" />
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="onCloseModal()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          size="sm"
          @click="onSubmit()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import promoApi from '@api/promo_headers';
import { queryParameters } from '@/schema';

export default {
  name: 'PromosPage',
  components: {},
  data: () => ({
    onShowModal: false,
    dataSource: [],
    dataColumns: [
      { caption: 'Title', field: 'title' },
      { caption: 'Promo Code', field: 'promo_code' },
      { caption: 'Description', field: 'promo_description', encodeHtml: false },
    ],
    formData: {
      id: 0,
      module: '',
      description: '',
      status: 'O',
    },
    totalCount: 0,
    page: 0,
    size: 0,
    status: 'O',
    keyword: null,
  }),
  mounted() {
    this.loadPromos();
  },
  watch: {
    page(v) {
      this.loadPromos();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadPromos();
      }
    },
    status(v) {
      this.loadPromos();
    },
    keyword(v) {
      this.loadPromos();
    },
  },
  methods: {
    loadPromos() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: this.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      if (this.keyword) {
        params.filter = this.keyword;
      }

      promoApi
        .list(params)
        .then(({ data }) => {
          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      const onSuccess = () => {
        this.loadPromos();
        this.onCloseModal();
      };

      if (!this.formData.id) {
        promoApi
          .add(this.formData)
          .then(({ data }) => {
            onSuccess();
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });

        return;
      }

      promoApi
        .update(this.formData)
        .then(({ data }) => {
          onSuccess();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onViewDetail(event) {
      const url = `/pages/e-commerce/promos/view?id=${event.key}`;
      this.$router.push(url);
    },
    onManage(event) {
      this.onShowModal = true;

      if (event.name === 'edit') {
        this.formData = event.data;
      }
    },
    onDelete(event) {
      promoApi
        .delete(event)
        .then(({ data }) => {
          this.loadPromos();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onCloseModal() {
      this.onShowModal = false;
      this.formData = {
        id: 0,
        module: '',
        description: '',
        status: 'O',
      };
    },
  },
};
</script>

<style></style>
