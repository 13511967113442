<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1 class="mb-0">Sales Order Manage</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <validation-observer ref="salesOrderFormRef" #default="{ invalid }">
          <b-card>
            <b-tabs v-model="activeTab" fill>
              <b-tab title="Information" active>
                <b-form @submit.prevent="onSubmit">
                  <b-row>
                    <b-col>
                      <h2>Customer Information</h2>
                    </b-col>

                    <!-- <b-col class="text-right">
                      <CompanySearch @on-select="(v) => onSelectCompany(v)" />
                    </b-col> -->
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-group label="Ticket No." label-for="v-ticket_no">
                        <b-form-input
                          disabled
                          v-model="formData.ticket_no"
                          id="v-ticket_no"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="9">
                      <b-form-group label="Name" label-for="v-company_name">
                        <b-form-input
                          v-model="formData.company_name"
                          id="v-company_name"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label-for="v-email">
                        <template v-slot:label>
                          Email <span class="text-danger">*</span>
                        </template>
                        <validation-provider
                          #default="{ errors }"
                          name="Email"
                          rules="required|email"
                        >
                          <b-form-input
                            :class="errors.length > 0 ? 'is-invalid' : null"
                            :state="errors.length > 0 ? false : null"
                            v-model="formData.email"
                            id="v-email"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col>
                      <b-form-group
                        label="Sales Person"
                        label-for="v-sales-person"
                      >
                        <v-select
                          v-model="formData.employee_id"
                          :options="users"
                          :reduce="(users) => users.value"
                        />
                      </b-form-group>
                    </b-col> -->
                    <b-col>
                      <b-form-group
                        label="Request Date"
                        label-for="v-transaction_date"
                      >
                        <b-form-input
                          id="v-transaction_date"
                          v-model="formData.preview_transaction_date"
                          type="datetime-local"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Status" label-for="v-status">
                        <b-form-select
                          id="v-status"
                          v-model="formData.status"
                          :options="status"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Billing Address</h2>
                    </b-col>
                  </b-row>
                  <!-- <b-row>
                    <b-col>
                      <b-form-group label="Attn" label-for="v-attn">
                        <b-form-input
                          v-model="formData.billing_name"
                          id="v-attn"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Company"
                        label-for="v-billing_company"
                      >
                        <b-form-input
                          v-model="formData.billing_company"
                          id="v-billing_company"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Email" label-for="v-billing_email">
                        <b-form-input
                          v-model="formData.billing_email"
                          id="v-billing_email"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row> -->
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Building/Block/House No and Street Name"
                        label-for="v-billing_addr1"
                      >
                        <b-form-input
                          v-model="formData.billing_addr1"
                          id="v-billing_addr1"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Unit # and Building Name"
                        label-for="v-billing_addr2"
                      >
                        <b-form-input
                          v-model="formData.billing_addr2"
                          id="v-billing_addr2"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Country"
                        label-for="v-billing_country"
                      >
                        <v-select
                          v-model="formData.billing_country"
                          :options="
                            $store.getters['hbaseGeneral/getAllCountries']
                          "
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Postal" label-for="v-billing_postal">
                        <b-form-input
                          v-model="formData.billing_postal"
                          id="v-billing_postal"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Telephone No."
                        label-for="v-billing_tel"
                      >
                        <b-form-input
                          v-model="formData.billing_tel"
                          id="v-billing_tel"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Fax" label-for="v-billing_fax">
                        <b-form-input
                          v-model="formData.billing_fax"
                          id="v-billing_fax"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Delivery Address</h2>
                    </b-col>

                    <!-- <b-col class="text-right">
                      <CompanyAddressSearch
                        v-if="formData.company_id"
                        :company_id="formData.company_id"
                        @on-select="(v) => selectCompanyAddress(v)"
                      />
                    </b-col> -->
                  </b-row>
                  <!-- <b-row>
                    <b-col>
                      <b-form-checkbox v-model="isSameAddress">
                        Is Delivery Address the same as Billing Address?
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Attn" label-for="v-delivery_name">
                        <b-form-input
                          v-model="formData.delivery_name"
                          id="v-delivery_name"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Company"
                        label-for="v-delivery_company"
                      >
                        <b-form-input
                          v-model="formData.delivery_company"
                          id="v-delivery_company"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Email" label-for="v-delivery_email">
                        <b-form-input
                          v-model="formData.delivery_email"
                          id="v-delivery_email"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row> -->
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Building/Block/House No and Street Name"
                        label-for="v-delivery_addr1"
                      >
                        <b-form-input
                          v-model="formData.delivery_addr1"
                          id="v-delivery_addr1"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Unit # and Building Name"
                        label-for="v-delivery_addr2"
                      >
                        <b-form-input
                          v-model="formData.delivery_addr2"
                          id="v-delivery_addr2"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Country"
                        label-for="v-delivery_country"
                      >
                        <v-select
                          v-model="formData.delivery_country"
                          :options="
                            $store.getters['hbaseGeneral/getAllCountries']
                          "
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Postal"
                        label-for="v-delivery_postal"
                      >
                        <b-form-input
                          v-model="formData.delivery_postal"
                          id="v-delivery_postal"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Telephone No."
                        label-for="v-delivery_tel"
                      >
                        <b-form-input
                          v-model="formData.delivery_tel"
                          id="v-delivery_tel"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Fax" label-for="v-delivery_fax">
                        <b-form-input
                          v-model="formData.delivery_fax"
                          id="v-delivery_fax"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <!-- <b-row>
                    <b-col>
                      <h2>GST and Currency</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="4">
                      <b-form-group label="GST" label-for="v-gst">
                        <b-form-select
                          @change="onSelectGST($event)"
                          v-model="formData.gst"
                          :options="gsts"
                          id="v-gst"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                      <b-form-group
                        label="GST Percent"
                        label-for="v-gst_percent"
                      >
                        <b-form-input
                          type="number"
                          v-model="formData.gst_percent"
                          id="v-gst_percent"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Currency" label-for="v-currency_id">
                        <b-form-select
                          v-model.number="formData.currency_id"
                          :options="currencies"
                          :reduce="(currencies) => +currences.value"
                          id="v-currency_id"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row> -->

                  <b-row>
                    <b-col>
                      <h2>Other Information</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="Months of Servicing"
                        label-for="v-servicing"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.servicing"
                          id="v-servicing"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                      <b-form-group label="Promo Code" label-for="v-promo_code">
                        <b-form-input
                          disabled
                          v-model="formData.promo_code"
                          id="v-promo_code"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                      <b-form-group
                        label="Discount Amount"
                        label-for="v-discount_amount"
                      >
                        <b-form-input
                          disabled
                          type="number"
                          v-model="formData.discount_amount"
                          id="v-discount_amount"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                      <b-form-group
                        label="Discount Percent"
                        label-for="v-discount_percent"
                      >
                        <b-form-input
                          disabled
                          type="number"
                          v-model="formData.discount_percent"
                          id="v-discount_percent"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group>
                        <SalesOrderDetails
                          :data-source="dataSourceSalesOrderDetails"
                          :form-data="formData"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="6">
                      <h2>Terms &amp; Conditions</h2>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <quill-editor
                          ref="quillEditorRef"
                          v-model="content"
                          id="editor"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="text-right">
                      <b-button
                        @click="goBack()"
                        type="button"
                        class="mr-1"
                        variant="outline-danger"
                      >
                        Back
                      </b-button>
                      <b-button
                        :disabled="invalid"
                        v-b-modal.tab-information
                        type="button"
                        variant="primary"
                      >
                        {{ onUpdate }}
                      </b-button>

                      <Modal
                        modalId="tab-information"
                        @onChange="(v) => onSubmit()"
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>

              <span v-if="$route.query.id">
                <b-tab title="Notes">
                  <Notes
                    ref="notesRef"
                    module="salesorder"
                    :header-id="parseInt(formData.id)"
                  />
                </b-tab>
                <b-tab title="Documents">
                  <Documents
                    ref="documentsRef"
                    module="salesorder"
                    :header-id="parseInt(formData.id)"
                  />
                </b-tab>
                <b-tab title="Logs">
                  <Logs module="salesorder" />
                </b-tab>
                <b-tab title="Comments">
                  <Comments module="salesorder" />
                </b-tab>
              </span>
            </b-tabs>
          </b-card>
        </validation-observer>
      </b-col>
    </b-row>
  </section>
</template>

<script>
  import salesOrderApi from '@api/sales_order_headers';
  import gstsApi from '@api/gsts';
  import currenciesApi from '@api/currencies';
  import usersApi from '@api/users';
  import { required } from '@validations';
  import { queryParameters } from '@/schema';

  import SalesOrderDetails from './components/SalesOrderDetails';

  export default {
    name: 'salesOrderManage',
    components: {
      SalesOrderDetails,
    },
    data: () => ({
      required,
      activeTab: 0,
      content: '',
      dataSourceSalesOrderDetails: [],
      formData: {
        billing_name: null,
        billing_company: null,
        billing_email: null,
        billing_addr1: null,
        billing_addr2: null,
        billing_country: 'Singapore',
        billing_postal: null,
        billing_tel: null,
        billing_fax: null,

        delivery_name: null,
        delivery_company: null,
        delivery_email: null,
        delivery_addr1: null,
        delivery_addr2: null,
        delivery_country: 'Singapore',
        delivery_postal: null,
        delivery_tel: null,
        delivery_fax: null,

        status: 'O',
      },
      gsts: [],
      currencies: [],
      users: [],
      status: [
        { text: 'Open', value: 'O' },
        { text: 'Confirmed', value: 'C' },
        { text: 'Void', value: 'V' },
        { text: 'Expired', value: 'E' },
      ],
      isSameAddress: false,
    }),
    mounted() {
      this.loadAvailableTechnicians();
      this.loadGSTs();
      this.loadCurrencies();

      const { quill } = this.$refs.quillEditorRef;
      if (quill) {
        quill.root.innerHTML =
          this.$store.getters['hbaseGeneral/getTermsCondition'];
      }

      this.formData.preview_transaction_date = this.defaultDateTime();

      if (+this.$route.query.id) {
        this.loadSalesOrder();
      }
    },
    watch: {
      activeTab(v) {
        if (v === 1) {
          this.$refs.notesRef.loadNotes();
        } else if (v === 2) {
          this.$refs.documentsRef.loadDocuments();
        }
      },
      isSameAddress(v) {
        const data = this.formData;

        if (v) {
          data.delivery_name = data.billing_name;
          data.delivery_company = data.billing_company;
          data.delivery_email = data.billing_email;
          data.delivery_addr1 = data.billing_addr1;
          data.delivery_addr2 = data.billing_addr2;
          data.delivery_country = data.billing_country;
          data.delivery_postal = data.billing_postal;
          data.delivery_tel = data.billing_tel;
          data.delivery_fax = data.billing_fax;
        } else {
          data.delivery_name = '';
          data.delivery_company = '';
          data.delivery_email = '';
          data.delivery_addr1 = '';
          data.delivery_addr2 = '';
          data.delivery_country = 'Singapore';
          data.delivery_postal = '';
          data.delivery_tel = '';
          data.delivery_fax = '';
        }
      },
    },
    computed: {
      onUpdate: {
        get() {
          return this.$route.query.id && !this.$route.query.duplicate
            ? 'Update'
            : 'Submit';
        },
      },
    },
    methods: {
      loadAvailableTechnicians() {
        const params = {
          sort: queryParameters.sort,
          page: { size: 1000 },
          status: queryParameters.status,
          filterExt: `roles=user&coyuser&custuser&tech`,
        };

        usersApi
          .list(params)
          .then(({ data }) => {
            const items = [];

            for (let i = 0; i < data.length; i++) {
              const item = data[i];

              let fullName = item.first_name;
              if (item.last_name) {
                fullName += ` ${item.last_name}`;
              }

              items.push({ label: fullName, value: +item.id, item });
            }

            this.users = items;
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      },
      loadGSTs() {
        const params = {
          sort: queryParameters.sort,
          page: queryParameters.page,
          status: queryParameters.status,
        };

        gstsApi
          .list(params)
          .then(({ data }) => {
            const items = [];
            for (const item of data) {
              const { description, percent } = item;
              const descriptionWithPercent = `${description} - ${percent}%`;
              items.push({
                value: item.name,
                text: descriptionWithPercent,
                percent,
                item,
              });
            }

            if (!this.formData.gst) {
              this.formData.gst = items[0].value;
              this.formData.gst_percent = items[0].percent;
            }

            this.gsts = items;
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      },
      loadCurrencies() {
        const params = {
          sort: queryParameters.sort,
          page: queryParameters.page,
          status: queryParameters.status,
        };

        currenciesApi
          .list(params)
          .then(({ data }) => {
            const items = [];
            for (const item of data) {
              items.push({ value: +item.id, text: item.description, item });
            }

            this.currencies = items;
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      },
      loadSalesOrder() {
        salesOrderApi
          .get(this.$route.query.id)
          .then(({ data }) => {
            if (data != null) {
              const { details } = data;
              this.dataSourceSalesOrderDetails = details.data ?? [];
              data.preview_transaction_date = this.inputDateTimeFromUnix(
                data.transaction_date
              );

              this.formData = data;
            }
          })
          .catch((err) => {
            //
          })
          .finally(() => {
            //
          });
      },
      onSubmit() {
        const success = () => {
          window.scrollTo(0, 0);
          this.toastConfig();
        };

        const error = () => {
          window.scrollTo(0, 0);
          this.toastConfig('', 'error');
        };

        const data = this.formData;
        const details = this.dataSourceSalesOrderDetails;

        if (details.length > 0) {
          let items = [];
          for (const item of details) {
            delete item.id;
            item.quantity = +item.quantity;
            item.gst = data.gst;
            item.gst_percent = data.gst_percent;

            items.push(item);
          }

          data.items = items;
          data.total = String(details.reduce((a, b) => a + b.total, 0));
          data.quantity = details.reduce((a, b) => +a + +b.quantity, 0);
        }

        data.transaction_date = this.inputDateTimeToUnix(
          data.preview_transaction_date
        );
        data.tnc = this.content;

        this.$refs.salesOrderFormRef.validate().then((validateSuccess) => {
          if (validateSuccess) {
            let app = null;

            if (!data.id) {
              app = salesOrderApi.add(data);
            } else {
              app = salesOrderApi.update(data);
            }

            if (app) {
              app
                .then(({ data }) => {
                  success();
                  this.$router.push(
                    `/pages/sales-tools/sales-order/view?id=${data.id}`
                  );
                })
                .catch((err) => {
                  error();
                })
                .finally(() => {
                  //
                });
            }
          }
        });
      },
      onSelectCompany(event) {
        if (event) {
          const formData = {};

          if (event.currency_id) {
            const currency = this.currencies.find((x) => {
              return +x.item.id === event.currency_id;
            });

            formData.currency_id = currency.value;
          }

          const {
            id: company_id,
            company_name,
            company_contacts,
            addr1,
            addr2,
            country,
            postal,
            telephone_1,
            fax,
            gst,
            gst_percent,
            email: company_email,
          } = event;

          console.log(event, company_email);

          const { id, preview_transaction_date, status, ticket_no } =
            this.formData;

          if (company_contacts && company_contacts.data) {
            const contacts = company_contacts.data;

            if (contacts.length > 0) {
              const [contact] = contacts;
              const { first_name, last_name, email, tel, mobile } = contact;

              formData.email = email;
              formData.tel = tel;
              formData.mobile = mobile;

              formData.billing_name = first_name;

              if (last_name) {
                formData.billing_name += ` ${last_name}`;
              }

              formData.billing_email = email;
            } else {
              formData.email = null;
              formData.tel = null;
              formData.mobile = null;
            }
          }

          formData.email = formData.email ? formData.email : company_email;
          formData.company_id = parseInt(company_id);
          formData.company_name = company_name;
          formData.gst = gst;
          formData.gst_percent = gst_percent;

          formData.addr1 = addr1;
          formData.addr2 = addr2;
          formData.country = country ?? 'Singapore';
          formData.postal = postal;
          formData.tel = telephone_1;
          formData.fax = fax;

          formData.billing_email = formData.billing_email
            ? formData.billing_email
            : company_email;

          formData.billing_company = company_name;
          formData.billing_addr1 = addr1;
          formData.billing_addr2 = addr2;
          formData.billing_country = country ?? 'Singapore';
          formData.billing_postal = postal;
          formData.billing_tel = telephone_1;
          formData.billing_fax = fax;

          formData.delivery_name = '';
          formData.delivery_company = '';
          formData.delivery_email = '';
          formData.delivery_addr1 = '';
          formData.delivery_addr2 = '';
          formData.delivery_country = 'Singapore';
          formData.delivery_postal = '';
          formData.delivery_tel = '';
          formData.delivery_fax = '';
          formData.preview_transaction_date = preview_transaction_date;
          formData.status = status;
          formData.ticket_no = ticket_no;
          formData.id = id;

          this.formData = Object.assign({}, formData);
        }
      },
      selectCompanyAddress(item) {
        this.isSameAddress = false;

        setTimeout(() => {
          if (item) {
            const data = this.formData;
            const { company_contact } = item;

            if (company_contact && company_contact.data) {
              const { data: cc } = company_contact;
              data.delivery_name = cc.first_name;
              if (cc.last_name) {
                data.delivery_name += ` ${cc.last_name}`;
              }

              data.delivery_email = cc.email;
              data.delivery_company = data.company;
            }

            data.delivery_addr1 = item.addr1;
            data.delivery_addr2 = item.addr2;
            data.delivery_country = item.country || 'Singapore';
            data.delivery_postal = item.postal;
            data.delivery_tel = item.tel;
            data.delivery_fax = item.fax;
          }
        }, 100);
      },
      goBack() {
        this.$router.push('/pages/sales-tools/sales-order');
      },
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
