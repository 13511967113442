<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col :md="isTogglingView && toggledViewData ? '8' : '12'">
        <b-card>
          <b-card-text>
            <DxGrid
              title="Reports Designer"
              url="/pages/dev-tools/report-designer/manage"
              toggleView
              toggleQuickEdit
              :data-source="dataSource"
              @onToggleView="(v) => onToggleView(v)"
              @on-delete="(v) => onDelete(v)"
              @on-update="(v) => onUpdate(v)"
              @on-update-cancel="(v) => loadReportsDesigner()"
            >
              <template #columns>
                <DxColumn data-field="rpt_title" caption="Title" />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        :md="isTogglingView ? '4' : '12'"
        v-if="isTogglingView && toggledViewData"
      >
        <b-card>
          <b-card-text>
            <ViewReportsDesignerDetail
              v-if="isTogglingView"
              :report-designer-data="toggledViewData"
            />
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import ViewReportsDesignerDetail from './QuickView.vue';
import reportsDesignerApi from '@api/reports_designer';
import { queryParameters } from '@/schema';

export default {
  name: 'ReportsDesignerPage',
  components: {
    ViewReportsDesignerDetail,
  },
  data: () => ({
    isTogglingView: false,
    toggledViewData: null,
    dataSource: [],
  }),
  mounted() {
    this.loadReportsDesigner();
  },
  methods: {
    loadReportsDesigner() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      reportsDesignerApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            this.dataSource = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    handleEvent(action, event) {
      this.$nextTick(() => {
        //
      });
    },

    onToggleView(event) {
      this.isTogglingView = event;

      if (!event) {
        this.toggledViewData = null;
      }
    },
    onViewDetail(event) {
      const data = event && event.row && event.row.data ? event.row.data : null;
      if (this.isTogglingView) {
        if (data) {
          this.toggledViewData = Object.assign({}, data);
        }

        return;
      }

      const url = `/pages/dev-tools/report-designer/view?id=${data.id}`;
      this.$router.push(url);
    },
    onUpdate(event) {
      const data = event.data;
    },
    onDelete(event) {
      reportsDesignerApi
        .delete(event)
        .then(() => {
          this.loadReportsDesigner();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onPrintPDF(event) {
      const data = event?.row?.data;

      if (data) {
      }
    },
  },
};
</script>

<style></style>
