<template>
  <section id="dashboard-ecommerce">
    <b-card>
      <b-row>
        <b-col cols="12" md="1">
          <b-form-group>
            <b-button
              @click="currentDate = new Date()"
              variant="primary"
              title="Today"
            >
              Today
            </b-button>
          </b-form-group>
        </b-col>
        <b-col cols="12" :md="isBreakpoint ? 5 : 11">
          <b-form-group>
            <v-select
              placeholder="Job Status..."
              v-model="jobStatus"
              :options="options"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="isBreakpoint" cols="12" md="6">
          <b-form-group>
            <v-select
              placeholder="Crew"
              v-model="crewSelected"
              label="display_name"
              :options="allUsers"
              :reduce="(user) => user.id"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <DxScheduler
              time-zone="Asia/Singapore"
              :data-source="dataSource"
              :current-date="currentDate"
              :views="views"
              :groups="groups"
              :height="800"
              :start-day-hour="0"
              current-view="day"
              data-cell-template="dataCellTemplate"
              date-cell-template="dateCellTemplate"
              time-cell-template="timeCellTemplate"
              resource-cell-template="resourceCellTemplate"
              appointment-template="appointmentTemplate"
              :on-appointment-click="onAppointmentClick"
              :on-appointment-dbl-click="onAppointmentDblClick"
              :on-appointment-form-opening="onAppointmentFormOpening"
              :on-appointment-adding="onAppointmentAdding"
              :on-appointment-updating="onAppointmentUpdating"
              :on-option-changed="onOptionChanged"
              :on-cell-click="onCellClick"
              :show-all-day-panel="false"
            >
              <DxResource
                :use-color-as-default="true"
                :data-source="jobs"
                field-expr="reference_id"
              />
              <DxResource
                :data-source="users"
                :allow-multiple="false"
                label="Employee"
                field-expr="job_assigned_to_1"
              />

              <template #resourceCellTemplate="{ data: user }">
                <b-row>
                  <b-col>
                    <div class="" style="margin-bottom: 7px">
                      {{ user.data.display_name }}
                    </div>
                  </b-col>
                </b-row>
              </template>

              <template #dataCellTemplate="{ data: cellData }">
                <div
                  v-if="getNonWorkingHours(cellData) && currentView !== 'month'"
                  class="disable-date"
                />
                <div v-else>
                  {{ cellData.text }}
                </div>
              </template>

              <template #dateCellTemplate="{ data: cellData }">
                <div class="">
                  {{ cellData.text }}
                </div>
              </template>

              <template #timeCellTemplate="{ data: cellData }">
                <div class="">
                  {{ cellData.text }}
                </div>
              </template>
              <template
                #appointmentTemplate="{ data: { appointmentData: formData } }"
              >
                <div class="">
                  <div class="">
                    <strong>{{ formData.ticket_no }}</strong>
                    ({{ formData.job_type }})
                  </div>
                  <div class="">
                    {{ formData.company_name }} - {{ formData.address }}
                  </div>
                  <div class="">
                    <small class="font-italic">
                      {{ formData.preview_time_job_eta }} -
                      {{ formData.preview_time_job_etd }}
                    </small>
                  </div>
                </div>
              </template>
            </DxScheduler>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-pagination
            align="right"
            v-model="currentPage"
            :total-rows="totalCount"
            :per-page="perPage"
            prev-text="Prev"
            next-text="Next"
            first-number
            last-number
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      v-model="showModal"
      centered
      size="lg"
      @hide="closeModal()"
      no-close-on-backdrop
    >
      <template #modal-title>
        <strong>
          {{ getTitle }}
        </strong>
      </template>

      <b-row>
        <b-col cols="12" v-if="!toShowData">
          <b-form @submit.prevent="onSubmit">
            <b-row>
              <b-col>
                <b-form-group label="Job" label-for="v-job_assigned_to_1">
                  <v-select
                    v-model="formData.reference_id"
                    :options="dataSource"
                    label="text"
                    :reduce="(dataSource) => dataSource.id"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Start Date"
                  label-for="v-preview_job_eta-date"
                >
                  <b-form-input
                    id="v-preview_job_eta-date"
                    v-model="formData.preview_job_eta"
                    type="datetime-local"
                    class="form-control"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="End Date"
                  label-for="v-preview_job_etd-date"
                >
                  <b-form-input
                    id="v-preview_job_etd-date"
                    v-model="formData.preview_job_etd"
                    type="datetime-local"
                    class="form-control"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
        <b-col cols="12" v-if="toShowData">
          <b-card>
            <b-row>
              <b-col>
                <b-form-group>
                  <div class="">
                    <strong>{{ formData.text }}</strong>
                  </div>
                  <div class="">
                    <small>
                      {{ formData.address }}
                    </small>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <div class="">Start Date: {{ formData.preview_job_eta }}</div>
                  <div class="">End Date: {{ formData.preview_job_etd }}</div>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  {{ formData.remarks }}
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="toDuplicate || toReassign">
              <b-col>
                <b-form-group
                  :label="toDuplicate ? 'Duplicate to' : 'Reassign To'"
                  label-for="v-job_assigned_to_1"
                >
                  <v-select
                    v-model="formData.job_assigned_to_1"
                    :options="users"
                    label="display_name"
                    :reduce="(users) => users.id"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="text-right">
                <b-button-group size="sm">
                  <b-button
                    @click="toDuplicate = !toDuplicate"
                    variant="outline-success"
                    title="Copy Job"
                  >
                    <feather-icon icon="CopyIcon" size="16" />
                  </b-button>
                  <b-button
                    @click="onViewDetail(formData.id)"
                    variant="outline-info"
                    title="View Job"
                  >
                    <feather-icon icon="EyeIcon" size="16" />
                  </b-button>
                  <b-button
                    @click="onEdit(formData.id)"
                    variant="outline-primary"
                    title="Edit"
                  >
                    <feather-icon icon="EditIcon" size="16" />
                  </b-button>
                  <b-button
                    @click="toReassign = !toReassign"
                    variant="outline-secondary"
                    title="Move to"
                  >
                    <feather-icon icon="ArrowRightIcon" size="16" />
                    &nbsp;
                    <feather-icon icon="UserIcon" size="16" />
                  </b-button>
                  <b-button
                    @click="onArchive()"
                    variant="outline-warning"
                    title="Void Job"
                  >
                    <feather-icon icon="ArchiveIcon" size="16" />
                  </b-button>
                  <b-button
                    v-b-modal.unassign
                    variant="outline-danger"
                    title="Unassign"
                  >
                    <feather-icon icon="XCircleIcon" size="16" />
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button
          @click="closeModal()"
          size="sm"
          variant="danger"
          class="bg-darken-4"
        >
          Close
        </b-button>
        <b-button
          v-if="!toShowData"
          size="sm"
          v-b-modal.tab-information
          type="button"
          variant="success"
        >
          Submit
        </b-button>
        <b-button
          v-if="toDuplicate"
          size="sm"
          v-b-modal.duplicate
          type="button"
          variant="success"
        >
          Submit
        </b-button>
        <b-button
          v-if="toReassign"
          size="sm"
          v-b-modal.reassign
          type="button"
          variant="success"
        >
          Submit
        </b-button>
      </template>
    </b-modal>

    <Modal modalId="tab-information" @onChange="(v) => onSubmit()" />
    <Modal modalId="unassign" @onChange="(v) => onUnassign()" />
    <Modal modalId="duplicate" @onChange="(v) => onDuplicate()" />
    <Modal modalId="reassign" @onChange="(v) => onReassign()" />
  </section>
</template>

<script>
import usersApi from '@api/users';
import jobOrdersApi from '@api/joborder_headers';
import { queryParameters } from '@/schema';
// @todo change from utils classes to global-function
import {
  UtilsDate,
  unixToDisplayDateTime,
  unixToDisplayTime,
} from '@/utils/classes/dates';

export default {
  name: 'CalendarPage',
  computed: {
    getTitle: {
      get() {
        if (this.formData) {
          if (this.formData.technician) {
            return `Assigned to: ${this.formData.technician}`;
          }

          return `Details: ${this.formData.ticket_no}`;
        }
      },
    },
  },
  watch: {
    currentPage(v) {
      this.loadUsers(v);
    },
    jobStatus(v) {
      this.loadJobOrders();
    },
    crewSelected(v) {
      this.loadUsers();
    },
  },
  data: () => ({
    currentPage: 1,
    totalCount: 0,
    perPage: 7,
    showModal: false,
    assignJobTo: null,
    toShowData: false,
    toDuplicate: false,
    toReassign: false,
    formData: {},
    currentView: 'day',
    views: ['day', 'week', 'month'],
    groups: ['job_assigned_to_1'],
    currentDate: new Date(),
    users: [],
    allUsers: [{ id: 0, display_name: 'All' }],
    dataSource: [],
    jobs: [],
    jobStatus: '',
    crewSelected: '',
    options: [
      'all',
      'new',
      'accepted',
      'work in progress',
      'paused',
      'done',
      'completed',
    ],
    isBreakpoint: false,
  }),
  mounted() {
    const { offsetWidth } = this.$root.$el;

    this.isBreakpoint = offsetWidth <= 1820;
    this.perPage = this.isBreakpoint ? 1 : 7;

    if (this.isBreakpoint) {
      this.loadAllUsers();
    }

    this.loadUsers();

    window.addEventListener('resize', ({ target }) => {
      const { innerWidth } = target;

      this.isBreakpoint = innerWidth <= 1820;
      this.perPage = this.isBreakpoint ? 1 : 7;

      if (this.isBreakpoint) {
        this.loadAllUsers();
      }

      this.loadUsers();
    });

    this.loadJobOrders();
  },
  methods: {
    loadAllUsers() {
      const params = {
        sort: 'first_name',
        page: { size: 1000 },
        status: queryParameters.status,
        filterExt: `roles=tech`,
      };

      usersApi
        .list(params)
        .then(({ data }) => {
          if (data) {
            const items = [{ id: 0, display_name: 'All' }];

            for (const item of data) {
              item.id = parseInt(item.id);

              items.push(item);
            }

            this.allUsers = items;
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadUsers(current = 1) {
      const params = {
        sort: 'first_name',
        page: { size: this.perPage, after: current },
        status: queryParameters.status,
        filterExt: `roles=tech`,
      };

      if (this.crewSelected.length > 0 && this.isBreakpoint) {
        params.filterExt += `,id=${this.crewSelected.join('|')}`;
      }

      usersApi
        .list(params)
        .then(({ data, meta }) => {
          if (data) {
            const items = this.isBreakpoint
              ? []
              : [{ id: 0, display_name: 'N/A' }];

            for (const item of data) {
              item.id = parseInt(item.id);

              items.push(item);
            }

            this.users = items;
          }

          this.totalCount = meta.page.total;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadJobOrders() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000, after: 1 },
        status: queryParameters.status,
      };

      const { jobStatus } = this;

      if (jobStatus !== 'all' && jobStatus !== '') {
        let job_status = '';
        if (jobStatus === 'new') {
          job_status = '0';
        }

        if (jobStatus === 'accepted') {
          job_status = '1';
        }

        if (jobStatus === 'work in progress') {
          job_status = '2';
        }

        if (jobStatus === 'paused') {
          job_status = '3';
        }

        if (jobStatus === 'done') {
          job_status = '4';
        }

        if (jobStatus === 'completed') {
          job_status = '5';
        }

        params.filterExt = `job_status=${job_status}`;
      }

      jobOrdersApi
        .list(params)
        .then(({ data }) => {
          if (data.length > 0) {
            const items = data.map((x) => {
              const { job_eta, job_etd, joborder_jobrequest_header: jr } = x;

              x.preview_job_eta = unixToDisplayDateTime(job_eta);
              x.preview_job_etd = unixToDisplayDateTime(job_etd);
              x.preview_time_job_eta = unixToDisplayTime(job_eta);
              x.preview_time_job_etd = unixToDisplayTime(job_etd);
              x.startDate = new UtilsDate(job_eta).unixToDate();
              x.endDate = new UtilsDate(job_etd).unixToDate();
              x.text = `${x.ticket_no} (${x.company_name})`;

              return x;
            });

            this.dataSource = items;

            const jobs = data.map((x) => {
              const { job_status, id } = x;
              x.reference_id = id;

              let color = '';
              if (job_status === '0') {
                color = 'rgb(0 123 255)';
              }

              if (job_status === '1') {
                color = 'rgb(28 231 255)';
              }

              if (job_status === '2') {
                color = 'rgb(255 185 118)';
              }

              if (job_status === '3') {
                color = 'rgb(240 129 130)';
              }

              if (job_status === '4') {
                color = 'rgb(72 218 137)';
              }

              if (job_status === '5') {
                color = 'rgb(156 160 164)';
              }

              x.color = color;

              return x;
            });

            this.jobs = jobs;
          } else {
            this.dataSource = [];
            this.jobs = [];
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    closeModal() {
      this.showModal = false;
      this.toDuplicate = false;
      this.toReassign = false;
      this.formData = {};
    },
    getNonWorkingHours(event) {
      const hours = event.startDate.getHours();
      return (hours <= 6 || hours >= 17) && !event.allDay;
    },
    onOptionChanged(event) {
      if (event && event.name === 'currentView') {
        this.currentView = event.value;
      }
    },
    onAppointmentClick(event) {
      event.cancel = true;
      console.log('onAppointmentClick: ', event);
      const { targetedAppointmentData } = event;

      this.formData = {};
      this.formData = targetedAppointmentData;
      this.formData.reference_id = targetedAppointmentData.id;
      this.showModal = true;
      this.toShowData = true;
    },
    onAppointmentDblClick(event) {
      event.cancel = true;
      console.log('onAppointmentDblClick: ', event);
    },
    onAppointmentFormOpening(event) {
      event.cancel = true;
      console.log('onAppointmentFormOpening: ', event);
    },
    onAppointmentAdding(event) {
      event.cancel = true;
      console.log('onAppointmentAdding: ', event);
    },
    onAppointmentUpdating(event) {
      event.cancel = true;
      console.log('onAppointmentUpdating: ', event);

      const { newData: formData } = event;
      formData.job_eta = new UtilsDate(formData.startDate).dateToUnix();
      formData.job_etd = new UtilsDate(formData.endDate).dateToUnix();

      const hours = formData.startDate.getHours();
      if (!((hours <= 6 || hours >= 17) && !event.allDay)) {
        jobOrdersApi
          .reassign(formData)
          .then(({ data }) => {
            if (data) {
              this.toastConfig();
              this.loadJobOrders();
            }
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
    onCellClick(event) {
      console.log('onCellClick: ', event);
      this.toShowData = false;

      const hours = event.cellData.startDate.getHours();
      if (hours <= 6 || hours >= 17) {
        event.cancel = true;
        return;
      }

      const { cellData } = event;
      const startDateunix = cellData.startDate / 1000;
      const endDateunix = cellData.endDate / 1000;
      const startDate = new UtilsDate(startDateunix).unixToInputDateTime();
      const endDate = new UtilsDate(endDateunix).unixToInputDateTime();

      const user = this.users.find((x) => {
        return x.id === cellData.groups.job_assigned_to_1;
      });

      this.formData = {
        reference_id: 0,
        preview_job_eta: startDate,
        preview_job_etd: endDate,
        job_assigned_to_1: cellData.groups.job_assigned_to_1,
        technician: user.display_name,
      };

      this.showModal = true;
    },
    onViewDetail(id) {
      const url = `/pages/services-tools/job-order/view?id=${id}`;
      this.$router.push(url);
    },
    onEdit(id) {
      const url = `/pages/services-tools/job-order/manage?id=${id}`;
      this.$router.push(url);
    },
    onSubmit() {
      const toUnix = (date) => {
        return new UtilsDate(date).dateToUnix();
      };

      const data = this.dataSource.find((x) => {
        return x.id === this.formData.reference_id;
      });

      if (data) {
        data.job_eta = toUnix(this.formData.preview_job_eta);
        data.job_etd = toUnix(this.formData.preview_job_etd);
        data.job_assigned_to_1 = this.formData.job_assigned_to_1;

        jobOrdersApi
          .reassign(data)
          .then(({ data }) => {
            if (data) {
              this.toastConfig();
              this.loadJobOrders();
              this.closeModal();
            }
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
    onUnassign() {
      this.formData.job_assigned_to_1 = 0;

      jobOrdersApi
        .reassign(this.formData)
        .then(({ data }) => {
          if (data) {
            this.toastConfig();
            this.loadJobOrders();
            this.closeModal();
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onDuplicate() {
      this.formData.reference_ticket_no = this.formData.ticket_no;
      this.formData.ticket_no = '';
      this.formData.created_at = null;
      this.formData.updated_at = null;
      this.formData.uuid = null;
      this.formData.id = null;

      jobOrdersApi
        .add(this.formData)
        .then(({ data }) => {
          if (data) {
            this.toastConfig();
            this.loadJobOrders();
            this.closeModal();
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onReassign() {
      jobOrdersApi
        .reassign(this.formData)
        .then(({ data }) => {
          if (data) {
            this.toastConfig();
            this.loadJobOrders();
            this.closeModal();
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onArchive() {
      jobOrdersApi
        .delete(this.formData.id)
        .then(({ data }) => {
          this.toastConfig();
          this.loadJobOrders();
          this.closeModal();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style>
.dx-scheduler-time-panel-cell {
  height: 20px;
}

.dx-scheduler-cell-sizes-vertical {
  height: 25px;
}

.disable-date {
  height: 100%;
  width: 100%;
  background-image: repeating-linear-gradient(
    135deg,
    rgba(244, 67, 54, 0.1),
    rgba(244, 67, 54, 0.1) 4px,
    transparent 4px,
    transparent 9px
  );
  color: #9b6467;
}
</style>
