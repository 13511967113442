/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard';
import appsAndPages from './apps-and-pages';
import others from './others';
import chartsAndMaps from './charts-and-maps';
import uiElements from './ui-elements';
import formAndTable from './forms-and-table';

import eCommerce from './hbase-navs/e-commerce';
import salesTools from './hbase-navs/sales-tools';
import servicingTools from './hbase-navs/servicing-tools';
import masterlists from './hbase-navs/masterlists';
import feedbacks from './hbase-navs/feedbacks';
import inventory from './hbase-navs/inventory';
import reports from './hbase-navs/reports';
import definitions from './hbase-navs/definitions';
import templates from './hbase-navs/templates';
import websites from './hbase-navs/website';
import monitorings from './hbase-navs/monitorings';
import trainings from './hbase-navs/trainings';
import relocation from './hbase-navs/relocation'

import { getUserData } from '@/auth/utils';

// Array of sections
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]

const userData = getUserData();

let items = [
  ...dashboard,
  ...salesTools,
  ...eCommerce,
  // ...inventory,
  ...servicingTools,
  ...monitorings,
  // ...relocation,
  // ...reports,
  ...feedbacks,
  // ...trainings,
  ...masterlists,
  ...definitions,
  ...templates,
  ...websites,
];

// if (userData) {
//   items = [
//     ...items,

//     ...appsAndPages,
//     ...uiElements,
//     ...formAndTable,
//     ...chartsAndMaps,
//     ...others,
//   ];
// }

export default items;
