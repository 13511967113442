<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>Manage Add/Edit Section Banner</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <validation-observer ref="formRef">
          <b-card>
            <b-tabs fill>
              <b-tab title="Information" active>
                <b-form @submit.prevent="onSubmit">
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <h2>Section Banner Information</h2>
                      </b-form-group>
                    </b-col>

                    <b-col class="text-right">
                      <b-button
                        type="button"
                        class="mr-1"
                        variant="outline-danger"
                        @click="$router.push('/pages/banners/section-banners')"
                      >
                        Back
                      </b-button>
                      <b-button
                        v-b-modal.tab-information
                        type="button"
                        variant="success"
                      >
                        Edit
                      </b-button>

                      <Modal
                        modalId="tab-information"
                        @onChange="(v) => onEdit()"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label="Section" label-for="v-section">
                        <b-form-select
                          disabled
                          id="v-section"
                          v-model="formData.section"
                          :options="sections"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Keyword" label-for="v-keyword">
                        <b-form-input
                          disabled
                          id="v-keyword"
                          v-model="formData.keyword"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Products</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group>
                        <SectionBannerProducts
                          :data-source="dataSourceDetails"
                          :is-disabled="true"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>
            </b-tabs>
          </b-card>
        </validation-observer>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import sectionBannersApi from '@api/section_banners';
import SectionBannerProducts from './components/SectionBannerProducts.vue';

export default {
  name: 'SectionBannerManage',
  components: {
    SectionBannerProducts,
  },
  data: () => ({
    dataSourceDetails: [],
    sections: [
      { text: 'Featured', value: 'F' },
      { text: 'Event Hall', value: 'EH' },
      { text: 'Flash Sale', value: 'FS' },
      { text: 'Latest News', value: 'LN' },
      { text: 'Latest Products', value: 'LP' },
      { text: 'Top Rated', value: 'TR' },
      { text: 'Email Promotions', value: 'SO' },
      { text: 'Best Sellers', value: 'BS' },
      { text: 'Retail Partners', value: 'RP' },
    ],
    formData: {
      id: 0,
      section: null,
      keyword: null,
    },
  }),
  mounted() {
    if (this.$route.query.id) {
      this.loadSectionBanner();
    }
  },
  methods: {
    loadSectionBanner() {
      sectionBannersApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          if (data != null) {
            this.formData = data;

            const { details } = data;
            if (details) {
              const items = details.data;

              const products = [];
              for (const item of items) {
                const { id, header_id, product } = item;

                if (product) {
                  const { name, sku: serial_no, brand, model } = product.data;
                  products.push({
                    id,
                    header_id,
                    name,
                    serial_no,
                    brand,
                    model,
                  });
                }
              }

              this.dataSourceDetails = products;
            }
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onEdit() {
      this.$router.push(
        `/pages/banners/section-banners/manage?id=${this.$route.query.id}`
      );
    },
  },
};
</script>

<style></style>
