export default [
  {
    title: 'E-Commerce',
    icon: 'ShoppingBagIcon',
    children: [
      {
        title: 'Web Sales Order',
        route: 'orders',
        icon: 'ListIcon',
      },
      {
        title: 'Promos',
        route: 'promos',
        icon: 'ListIcon',
      },
    ],
  },
];
