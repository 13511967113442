import api from '@apiInstance';

const endpoint = 'promo_headers';
export default {
  add(data) {
    return api.post(endpoint, data);
  },
  get(id) {
    return api.fetch(`${endpoint}/${id}`);
  },
  list(params) {
    return api.fetch(endpoint, { params });
  },
  update(data) {
    return api.patch(endpoint, data);
  },
  delete(id) {
    return api.remove(endpoint, id);
  },
};
