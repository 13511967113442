<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>Promo Information</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <validation-observer ref="promoRef">
          <b-card>
            <b-tabs v-model="activeTab" fill>
              <b-tab title="Information" active>
                <b-form @submit.prevent="onSubmit">
                  <b-row>
                    <b-col class="text-right">
                      <b-button
                        type="button"
                        class="mr-1"
                        variant="outline-danger"
                        @click="goBack()"
                      >
                        Back
                      </b-button>
                      <b-button
                        v-b-modal.tab-information
                        type="button"
                        variant="success"
                      >
                        Edit
                      </b-button>

                      <Modal
                        modalId="tab-information"
                        @onChange="(v) => onEdit()"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="4">
                      <b-form-group label="Title" label-for="v-title">
                        <b-form-input
                          disabled
                          v-model="formData.title"
                          id="v-title"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                      <b-form-group label="Promo Code" label-for="v-promo_code">
                        <b-form-input
                          disabled
                          v-model="formData.promo_code"
                          id="v-promo_code"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                      <b-form-group
                        label="Promo Status"
                        label-for="v-promo_status"
                      >
                        <b-form-select
                          disabled
                          v-model="formData.promo_status"
                          id="v-promo_status"
                          :options="statusOptions"
                        >
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Promo Description"
                        label-for="v-promo_description"
                      >
                        <quill-editor
                          disabled
                          id="v-promo_description"
                          ref="quillEditorRef"
                          v-model="formData.promo_description"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-group label="From Date" label-for="v-from_date">
                        <b-form-input
                          disabled
                          id="v-from_date"
                          v-model="fromDate"
                          type="datetime-local"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="To Date" label-for="v-to_date">
                        <b-form-input
                          disabled
                          id="v-to_date"
                          v-model="toDate"
                          type="datetime-local"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="Discount Percent"
                        label-for="v-discount_percent"
                      >
                        <b-form-input
                          disabled
                          id="v-discount_percent"
                          v-model="formData.discount_percent"
                          class="form-control text-right"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="Discount Amount"
                        label-for="v-discount_amount"
                      >
                        <b-form-input
                          disabled
                          id="v-discount_amount"
                          v-model="formData.discount_amount"
                          class="form-control text-right"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Details</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group>
                        <PromoDetails
                          :data-source="dataSourcePromoDetails"
                          :formData="formData"
                          :is-disabled="true"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" v-if="onImagePreview">
                      <b-form-group label="Preview">
                        <b-img
                          width="250"
                          height="250"
                          center
                          thumbnail
                          fluid
                          :src="onImagePreview"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>
              <span v-if="$route.query.id">
                <b-tab title="Logs">
                  <Logs ref="logsRef" module="joborder" />
                </b-tab>
                <b-tab title="Comments">
                  <Comments ref="commentsRef" module="joborder" />
                </b-tab>
              </span>
            </b-tabs>
          </b-card>
        </validation-observer>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import promoApi from '@api/promo_headers';
import statusApi from '@api/statuses';
import PromoDetails from './components/PromoDetails.vue';
import { getImage } from '@/global-functions';

export default {
  name: 'PromoManage',
  components: {
    PromoDetails,
  },
  data: () => ({
    activeTab: 0,
    isLoading: false,
    content: '',
    fromDate: null,
    toDate: null,
    imageUpload: null,
    imagePreview: null,
    statusOptions: [],
    dataSourcePromoDetails: [],
    formData: {
      id: 0,
      title: null,
      promo_code: null,
      promo_description: null,
      from_date: null,
      to_date: null,
      tac: null,
      tnc: null,
      promo_status: null,
      thumbnail: null,
      thumbnailbase64: null,
    },
  }),
  watch: {
    activeTab(index) {
      if (index === 1) {
        this.$refs.logsRef.loadLogs();
      }

      if (index === 2) {
        this.$refs.commentsRef.loadTimelineHeader();
      }
    },
    imageUpload(e) {
      if (e) {
        const reader = new window.FileReader();
        reader.readAsDataURL(e);
        reader.onload = () => {
          this.imagePreview = reader.result;

          this.formData.thumbnail = e.name;
          this.formData.thumbnailbase64 = reader.result;
        };
      }
    },
  },
  computed: {
    onUpdate: {
      get() {
        return this.$route.query.id ? 'Update' : 'Create';
      },
    },
    onImagePreview: {
      get() {
        const { thumbnailbase64, thumbnail, id } = this.formData;

        if (thumbnailbase64) {
          return this.imagePreview;
        }

        if (!thumbnailbase64 && thumbnail) {
          const getImageData = { id, thumbnail, model: 'promos' };

          return getImage(getImageData);
        }

        return '';
      },
    },
  },
  mounted() {
    this.fromDate = this.defaultDateTime();
    this.toDate = this.defaultDateTime();
    this.loadSystemCode();

    if (+this.$route.query.id) {
      this.loadPromo();
    }
  },
  methods: {
    goBack() {
      this.$router.push('/pages/e-commerce/promos');
    },
    loadPromo() {
      promoApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          const {
            promo_description,
            from_date,
            to_date,
            discount_amount,
            details,
          } = data;

          const { quill } = this.$refs.quillEditorRef;
          if (quill) {
            quill.root.innerHTML = promo_description;
          }

          this.fromDate = this.inputDateTimeFromUnix(from_date);
          this.toDate = this.inputDateTimeFromUnix(to_date);

          if (details.data.length > 0) {
            this.dataSourcePromoDetails = details.data;
          }

          if (discount_amount) {
            data.discount_amount = discount_amount.toFixed(2);
          }

          this.formData = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadSystemCode() {
      const params = {
        sort: '-updated_at',
        page: { size: 1000 },
        status: 'O',
      };

      statusApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            const items = [];

            for (const item of data) {
              const { code: value, name: text, selections } = item;
              items.push({ value, text, selections });
            }

            this.statuses = items;

            const status = items.find((x) => {
              return x.value === 'promo:status';
            });
            if (status) {
              const { selections } = status;
              this.statusOptions = selections;
            }
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onEdit() {
      const url = `/pages/e-commerce/promos/manage?id=${this.$route.query.id}`;
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
.pin:hover {
  cursor: pointer;
}

.isDisplaying.arrow_down {
  transform: rotate(180deg);
  transition: transform 0.25s linear;
}

.isDisplaying.arrow_up {
  transform: rotate(360deg);
  transition: transform 0.25s linear;
}
</style>
