<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <b-row>
              <b-col cols="12" md="6">
                <h1>Active Tickets (Potential)</h1>
              </b-col>
              <b-col cols="12" md="6" class="text-right">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="$router.push(url)"
                >
                  Add
                </b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <DxDataGrid
                    key-expr="id"
                    id="gridContainer"
                    :data-source="dataSource"
                    :focused-row-enabled="true"
                    :allow-column-reordering="true"
                    :allow-column-resizing="true"
                    :column-auto-width="true"
                    :show-borders="true"
                    :row-alternation-enabled="true"
                    @exporting="onExporting"
                    @row-removed="handleEvent('row-removed', $event)"
                    @row-updated="handleEvent('row-updated', $event)"
                    @edit-canceling="handleEvent('edit-canceling', $event)"
                    @edit-canceled="handleEvent('edit-canceled', $event)"
                    @row-click="handleEvent('row-click', $event)"
                    @cell-click="handleEvent('cell-click', $event)"
                  >
                    <DxPaging :page-size="totalCount" />

                    <DxColumnChooser :allow-search="true" :enabled="true" />
                    <DxColumnFixing :enabled="true" />
                    <DxSorting mode="multiple" />
                    <DxFilterRow :visible="true" />
                    <DxGroupPanel :visible="true" />
                    <DxGrouping :auto-expand-all="true" />
                    <DxHeaderFilter :visible="true" />
                    <DxSearchPanel location="before" :visible="true" />

                    <DxEditing
                      :allow-updating="true"
                      :allow-deleting="true"
                      :use-icons="true"
                      mode="row"
                    />

                    <DxColumn type="buttons">
                      <DxButton
                        icon="user"
                        hint="Add Crew"
                        :onClick="onAddAssignedTo"
                      />
                      <DxButton
                        icon="textdocument"
                        hint="View"
                        :onClick="onEditRemarks"
                      />
                      <DxButton name="delete" />
                    </DxColumn>

                    <DxColumn
                      v-for="(item, index) in fields"
                      :key="index"
                      :data-field="item.field"
                      :caption="item.caption"
                      :cell-template="item.template"
                    />

                    <DxToolbar>
                      <DxItem location="before" name="columnChooserButton" />
                      <DxItem location="before" name="exportButton" />
                      <DxItem location="before" name="searchPanel" />
                      <DxItem name="groupPanel" />
                    </DxToolbar>

                    <template #ticketNumberTemplate="{ data }">
                      <span
                        @click="onRedirect($event, data)"
                        class="cursor-pointer"
                        :class="{
                          'text-white': data.rowIndex === rowDataIndex,
                          'text-primary': data.rowIndex !== rowDataIndex,
                        }"
                      >
                        {{ data.value }}
                      </span>
                    </template>

                    <template #assignedToTemplate="{ data: { displayValue } }">
                      <div class="" v-for="(item, i) in displayValue" :key="i">
                        {{ item.display_name }}
                      </div>
                    </template>

                    <template #jobsTemplate="{ data: { displayValue } }">
                      <div class="">
                        <b-badge
                          class="d-block cursor-pointer"
                          :class="i + 1 !== displayValue.length ? 'mb-1' : ''"
                          v-for="(item, i) in displayValue"
                          :key="i"
                          :variant="onGetJobStatus(item.joborder)"
                          @click="onViewJob(item)"
                        >
                          {{ item.joborder.data.ticket_no }}
                        </b-badge>
                      </div>
                    </template>

                    <template #remarksTemplate="{ data }">
                      <div class="">
                        <div v-if="data.displayValue.length > 0">
                          <small>
                            {{
                              previewDateTime(data.displayValue[0].updated_at)
                            }}
                          </small>
                          -
                          {{ onEllipsis(data.displayValue[0].remarks) }}
                        </div>
                        <div v-if="data.displayValue.length > 1">
                          <small>
                            {{
                              previewDateTime(data.displayValue[1].updated_at)
                            }}
                          </small>
                          -
                          {{ onEllipsis(data.displayValue[1].remarks) }}
                        </div>
                        <div
                          v-if="data.displayValue.length > 2"
                          @click="showMoreRemarks($event, data.displayValue)"
                        >
                          <small
                            :class="{
                              'text-white': data.rowIndex === rowDataIndex,
                              'text-primary': data.rowIndex !== rowDataIndex,
                            }"
                            class="cursor-pointer"
                          >
                            show {{ isShowingMore ? 'less' : 'more' }}...
                          </small>
                        </div>
                      </div>
                    </template>
                  </DxDataGrid>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-nav>
                  <b-nav-item
                    @click="onSetPage(page)"
                    v-for="(page, index) in pageSize"
                    :key="index"
                  >
                    <span :class="page.isActive ? 'text-success' : ''">
                      {{ page.name }}
                    </span>
                  </b-nav-item>
                </b-nav>
              </b-col>
              <b-col>
                <b-pagination
                  align="right"
                  v-model="currentPage"
                  :total-rows="totalCount"
                  :per-page="perPage"
                  first-text="First"
                  prev-text="Prev"
                  next-text="Next"
                  last-text="Last"
                />
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="onShowAssignedToModal"
      centered
      @hide="onCloseModal()"
      size="sm"
      title="Assigned To"
      no-close-on-backdrop
      hide-footer
    >
      <b-row v-if="formData.display_name">
        <b-col>
          <b-form-group>
            <h2>
              {{ formData.display_name }}
            </h2>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="toShowSelection">
        <b-col cols="12">
          <b-form-group label="Crew" label-for="v-technician">
            <v-select
              id="v-technician"
              v-model.number="formData.assigned_to"
              :options="users"
              :reduce="(users) => users.value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button-group>
            <b-button
              v-if="formData.action === 'add'"
              variant="primary"
              @click="onSubmitCrew()"
              :disabled="!formData.assigned_to"
            >
              Assign
            </b-button>
            <b-button
              v-if="formData.action === 'edit' && toShowSelection"
              variant="success"
              @click="onSubmitCrew"
            >
              Submit Swap to
            </b-button>
            <b-button
              v-if="formData.action === 'edit'"
              variant="warning"
              @click="toShowSelection = !toShowSelection"
            >
              Swap Crew
            </b-button>
            <b-button
              v-if="formData.action === 'edit'"
              :disabled="assignedUsers.length === 1"
              variant="danger"
              @click="onDeleteAssignedTo(formData.id)"
            >
              Delete Crew
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      v-model="onShowJobModal"
      centered
      @hide="onCloseModal()"
      size="lg"
      :title="title"
      no-close-on-backdrop
    >
      <b-row>
        <b-col cols="12" md="3">
          <b-form-group label="Ticket No." label-for="v-ticket-no">
            <b-form-input
              disabled
              v-model="formData.ticket_no"
              id="v-ticket-no"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="9">
          <b-form-group label="Company" label-for="v-company">
            <b-form-input
              v-model="formData.company_name"
              id="v-company"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Report Date" label-for="v-report_date-date">
            <b-form-input
              disabled
              v-model="formData.preview_report_date"
              id="v-report_date-date"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Job Schedule Date" label-for="v-job_eta">
            <b-form-input
              disabled
              v-model="formData.preview_job_eta"
              id="v-job_eta"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Problem Description"
            label-for="v-problem_description"
          >
            <b-form-textarea
              disabled
              id="v-problem_description"
              v-model="formData.problem_description"
              rows="4"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Address" label-for="v-address">
            <b-form-input disabled v-model="formData.address" id="v-address" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h2>Contact Information</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Contact Person" label-for="v-poc">
            <b-form-input disabled v-model="formData.poc" id="v-poc" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Mobile" label-for="v-mobile">
            <b-form-input disabled v-model="formData.mobile" id="v-mobile" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h2>Other Information</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Instructions/Remarks" label-for="v-remarks">
            <b-form-textarea
              disabled
              v-model="formData.remarks"
              id="v-remarks"
              rows="4"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          :disabled="formData.job_status !== '10'"
          @click="onUpdateServiceStatus()"
          block
          variant="primary"
        >
          Update Service Request Status
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="onShowRemarksModal"
      centered
      @hide="onCloseModal()"
      title="Remarks"
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <b-form-textarea
            id="remarks"
            rows="3"
            v-model="remarksData.remarks"
          />
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="onCloseModal()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          size="sm"
          @click="onSubmitRemarks()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="onShowDateDueModal"
      centered
      @hide="onCloseModal()"
      size="sm"
      title="Date Due"
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <b-form-input
            id="v-date_due"
            v-model="formData.edit_date_due"
            type="datetime-local"
            class="form-control"
          />
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="onCloseModal()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          size="sm"
          @click="onSubmit()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="onShowStatusModal"
      centered
      @hide="onCloseModal()"
      size="sm"
      title="Service Status"
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <b-form-select
            id="v-service_status"
            v-model="formData.edit_service_status"
            :options="[
              'Potential',
              'Missed',
              'Aborted',
              'New',
              'Processing',
              'Completed',
              'Canceled',
            ]"
            class="form-control"
          />
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="onCloseModal()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          size="sm"
          @click="onSubmit()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="isShowingMore"
      centered
      @hide="onCloseModal()"
      size="lg"
      title="Remarks"
      no-close-on-backdrop
    >
      <b-row v-for="(item, index) in remarkItems" :key="index">
        <b-col>
          <b-card border-variant="secondary">
            <small>
              {{ previewDateTime(item.updated_at) }}
            </small>
            <b-card-text>
              {{ item.remarks }}
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="isShowingMore = false"
          variant="danger"
          class="bg-darken-4"
        >
          Close
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import ticketMonitoringsApi from '@api/ticket_monitorings';
import ticketMonitoringAssignedToApi from '@api/ticket_monitoring_assigned_to';
import ticketMonitoringRemarksApi from '@api/ticket_monitoring_remarks';
import usersApi from '@api/users';
import { queryParameters } from '@/schema';
// @todo change from utils classes to global-function
import { UtilsDate, unixToDisplayDateTime } from '@/utils/classes/dates';
import { getUserData } from '@/auth/utils';
import moment from 'moment';

export default {
  name: 'TicketMonitoringPage',
  components: {},
  data: () => ({
    onShowAssignedToModal: false,
    onShowJobModal: false,
    onShowRemarksModal: false,
    onShowDateDueModal: false,
    onShowStatusModal: false,
    toShowSelection: false,
    currentPage: 1,
    perPage: 10,
    totalCount: 0,
    pageSize: [
      { name: '10', value: 10, isActive: true },
      { name: '20', value: 20, isActive: false },
      { name: '50', value: 50, isActive: false },
      { name: '100', value: 100, isActive: false },
      { name: 'ALL', value: 10000, isActive: false },
    ],
    fields: [
      {
        caption: 'Ticket No.',
        field: 'ticket_no',
        template: 'ticketNumberTemplate',
      },
      { caption: 'Customer', field: 'company_name' },
      {
        caption: 'Assigned',
        field: 'ticket_monitoring_assigned_to.data',
        template: 'assignedToTemplate',
      },
      { caption: 'Date Raised', field: 'preview_date_raised' },
      { caption: 'Date Due', field: 'preview_date_due' },
      { caption: 'Timelapse', field: 'timelapse' },
      { caption: 'Status', field: 'service_status' },
      { caption: 'Service Type', field: 'service_type' },
      {
        caption: 'Job(s)',
        field: 'ticket_monitoring_jobs.data',
        template: 'jobsTemplate',
      },
      {
        caption: 'Latest Remarks',
        field: 'ticket_monitoring_remarks.data',
        template: 'remarksTemplate',
      },
    ],
    dataSource: [],
    assignedUsers: [],
    users: [],
    formData: {},
    formDataTicketMonitoring: {},
    title: null,
    remarksData: {
      id: 0,
      header_id: 0,
      remarks: null,
    },
    isShowingMore: false,
    rowDataIndex: -1,
    remarkItems: [],
  }),
  watch: {
    currentPage(v) {},
  },
  mounted() {
    this.loadUsers();
    this.loadTicketMonitorings();
  },
  methods: {
    loadUsers() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
        filterExt: `roles=user&coyuser&custuser&tech`,
      };

      const userData = getUserData();
      const { companyId } = userData;

      if (companyId) {
        params.filterExt += `,company_id=${companyId}`;
      }

      usersApi
        .list(params)
        .then(({ data }) => {
          const items = [];

          for (let i = 0; i < data.length; i++) {
            const item = data[i];

            let fullName = item.first_name;
            if (item.last_name) {
              fullName += ` ${item.last_name}`;
            }

            items.push({ label: fullName, value: +item.id, item });
          }

          this.users = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadTicketMonitorings(current = 1) {
      const params = {
        sort: queryParameters.sort,
        page: { size: this.perPage, after: current },
        status: queryParameters.status,
        filterExt: `service_status=Potential`,
      };

      const userData = getUserData();
      const { companyId } = userData;

      if (companyId) {
        params.filterExt += `,company_id=${companyId}`;
      }

      if (this.currentPage > 1) {
        params.page.after = this.currentPage;
      }

      ticketMonitoringsApi
        .list(params)
        .then(({ data, meta }) => {
          if (data.length > 0) {
            const items = data.map((x) => {
              x.preview_date_raised = unixToDisplayDateTime(x.date_raised);
              x.preview_date_due = unixToDisplayDateTime(x.date_due);

              const dueDate = moment(new Date(x.date_due * 1000));
              const currentDate = moment(new Date());

              const diff = moment.duration(currentDate.diff(dueDate));
              const days = Math.floor(diff.asDays());
              const hours = Math.floor(diff.asHours());
              const minutes = Math.floor(diff.asMinutes() % 60);

              let timeLapse = [];

              if (days) {
                timeLapse.push(`Days: ${days}`);
              }

              if (hours) {
                timeLapse.push(`Hours: ${hours}`);
              }

              if (minutes) {
                timeLapse.push(`Minutes: ${minutes}`);
              }

              x.timelapse = timeLapse.join(' ');

              return x;
            });

            this.dataSource = items;
            this.totalCount = meta.page.total;
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      const formData = this.formData;

      if (formData.edit_date_due) {
        formData.date_due = new UtilsDate(formData.edit_date_due).dateToUnix();
      }

      if (formData.edit_service_status) {
        formData.service_status = formData.edit_service_status;
      }

      ticketMonitoringsApi
        .update(formData)
        .then(({ data }) => {
          this.loadTicketMonitorings();
          this.onCloseModal();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmitRemarks() {
      ticketMonitoringRemarksApi
        .add(this.remarksData)
        .then(({ data }) => {
          this.loadTicketMonitorings();
          this.onCloseModal();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmitCrew() {
      let app;

      if (this.formData.id) {
        app = ticketMonitoringAssignedToApi.update(this.formData);
      } else {
        app = ticketMonitoringAssignedToApi.add(this.formData);
      }

      if (app) {
        app
          .then(({ data }) => {
            this.loadTicketMonitorings();
            this.onCloseModal();
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
    onSetPage(event) {
      for (let i = 0; i < this.pageSize.length; i++) {
        const page = this.pageSize[i];

        page.isActive = event.value === page.value;
      }

      this.perPage = event.value;
      this.loadTicketMonitorings();
    },
    onViewJob(event) {
      const { data } = event.joborder;
      const { addr1, addr2, country, postal } = data;

      data.address = addr1;

      if (addr2) {
        data.address += ` ${addr2}`;
      }

      if (country) {
        data.address += ` ${country}`;
      }

      if (postal) {
        data.address += ` ${postal}`;
      }

      data.preview_report_date = unixToDisplayDateTime(data.report_date);
      data.preview_job_eta = unixToDisplayDateTime(data.job_eta);
      this.formData = data;
      this.formDataTicketMonitoring = event;
      this.title = `Job ${data.ticket_no} (${data.job_type})`;
      this.onShowJobModal = true;
    },
    onAddAssignedTo(event) {
      const { data } = event.row;
      this.formData = {
        id: null,
        action: 'add',
        assigned_to: 0,
        header_id: parseInt(data.id),
      };

      this.onShowAssignedToModal = true;
      this.toShowSelection = true;
    },
    onEditAssignedTo(item, items) {
      if (item) {
        item.action = 'edit';
        this.formData = item;
        this.assignedUsers = items;
        this.onShowAssignedToModal = true;
      }
    },
    onEditRemarks(event) {
      const { row } = event;

      this.onShowRemarksModal = true;
      this.remarksData.header_id = parseInt(row.key);
    },
    onDelete(event) {
      ticketMonitoringsApi
        .delete(event)
        .then(({ data }) => {
          this.loadTicketMonitorings();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onDeleteAssignedTo(event) {
      ticketMonitoringAssignedToApi
        .delete(event)
        .then(({ data }) => {
          this.loadTicketMonitorings();
          this.onCloseModal();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onExporting(e) {
      const doc = new jsPDF();

      if (e.format == 'pdf') {
        exportDataGrid({
          jsPDFDocument: doc,
          component: e.component,
          indent: 5,
        }).then(() => {
          doc.save('rfqlist.pdf');
        });
      }
    },
    onCloseModal() {
      this.formData = {};
      this.remarksData = {};
      this.onShowAssignedToModal = false;
      this.onShowJobModal = false;
      this.onShowRemarksModal = false;
      this.onShowDateDueModal = false;
      this.onShowStatusModal = false;
      this.toShowSelection = false;
    },
    handleEvent(action, event) {
      const { columnIndex } = event;

      if (action === 'row-click') {
        this.rowDataIndex = event.dataIndex;
      }

      if (action === 'cell-click' && columnIndex === 3) {
        // this.onShowAssignedToModal = true;
      }

      if (action === 'cell-click' && columnIndex === 4) {
        const toLocaleString = (date) => {
          return new UtilsDate(date).unixToInputDateTime();
        };

        if (event.data) {
          this.onShowDateDueModal = true;
          event.data.edit_date_due = toLocaleString(event.data.date_due);
          this.formData = event.data;
        }
      }

      if (action === 'cell-click' && columnIndex === 6) {
        if (event.data) {
          this.onShowStatusModal = true;
          event.data.edit_service_status = event.data.service_status;
          this.formData = event.data;
        }
      }
    },
    previewDateTime(datetime) {
      return unixToDisplayDateTime(datetime);
    },
    onGetJobStatus(value) {
      if (!value.data) {
        return;
      }

      const { job_status } = value.data;

      let color = 'primary';

      if (job_status === '0') {
        color = 'primary';
      }

      if (job_status === '1') {
        color = 'info';
      }

      if (job_status === '2') {
        color = 'warning';
      }

      if (job_status === '3') {
        color = 'danger';
      }

      if (job_status === '4') {
        color = 'success';
      }

      if (job_status === '5') {
        color = 'secondary';
      }

      return color;
    },
    onUpdateServiceStatus() {
      const { header_id } = this.formDataTicketMonitoring;
      const data = this.dataSource.find((x) => {
        return parseInt(x.id) === header_id;
      });

      data.service_status = 'Completed';

      ticketMonitoringsApi
        .update(data)
        .then(({ data }) => {
          this.loadTicketMonitorings();
          this.onCloseModal();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onRedirect(event, data) {
      event.stopPropagation();
      const url = `/pages/services-tools/service-request/view?id=${data.key}`;
      window.open(url, '_blank');
    },
    showMoreRemarks(event, data) {
      event.stopPropagation();
      this.isShowingMore = !this.isShowingMore;
      this.remarkItems = data;
    },
    onEllipsis(text) {
      if (text.length > 25 && !this.isShowingMore) {
        return `${text.substring(0, 25)}...`;
      }

      return text;
    },
    onGetData(data) {
      console.log(data);
    },
  },
};
</script>

<style>
#gridContainer {
  /* margin: 20px 0; */
  height: 740x;
}
</style>
