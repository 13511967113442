<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>Manage Add/Edit Enrollment</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <validation-observer ref="userForm">
          <b-card>
            <b-tabs v-model="activeTab" fill>
              <b-tab title="Information" active>
                <b-row>
                  <b-col>
                    <b-form-group>
                      <h2>Enrollment Information</h2>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group
                      label="Training Name"
                      label-for="v-training_name"
                    >
                      <v-select
                        id="v-training_uuid"
                        v-model="formData.training_uuid"
                        label="text"
                        :options="trainings"
                        :reduce="(training) => training.value"
                      ></v-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Employee" label-for="v-employee_id">
                      <v-select
                        id="v-employee_id"
                        v-model="formData.employee_id"
                        label="display_name"
                        :options="users"
                        :reduce="(user) => +user.id"
                      ></v-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="Due Date" label-for="v-due_date">
                      <b-form-input
                        id="v-due_date"
                        v-model="formData.preview_due_date"
                        type="datetime-local"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Exam Date" label-for="v-exam_date">
                      <b-form-input
                        id="v-exam_date"
                        v-model="formData.preview_exam_date"
                        type="datetime-local"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Training Status"
                      label-for="v-exam_status"
                    >
                      <b-form-select
                        id="v-exam_status"
                        v-model="formData.exam_status"
                        :options="trainingStatuses"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Payment Amount"
                      label-for="v-payment_amount"
                    >
                      <b-form-input
                        id="v-payment_amount"
                        :class="{
                          'border-danger':
                            formData.payment_amount &&
                            !correctDecimalFormat.payment_amount,
                        }"
                        class="text-right"
                        v-model="formData.payment_amount"
                        @change="convertToDecimal('payment_amount')"
                        @input="incorrectDecimal('payment_amount')"
                        @keypress="isNumber"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Payment Reference"
                      label-for="v-payment_reference"
                    >
                      <b-form-input
                        id="v-payment_reference"
                        v-model="formData.payment_reference"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Payment Status"
                      label-for="v-payment_status"
                    >
                      <b-form-select
                        id="v-payment_status"
                        v-model="formData.payment_status"
                        :options="paymentStatuses"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Payment Receipt"
                      label-for="v-payment_receipt"
                    >
                      <b-form-file
                        id="v-payment_receipt"
                        v-model="fileUploadPaymentReceipt"
                      ></b-form-file>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="paymentReceiptPreview">
                  <b-col>
                    <b-form-group
                      label="Payment Receipt Preview"
                      label-for="v-payment_receipt_preview"
                    >
                      <b-img
                        center
                        thumbnail
                        fluid
                        :src="paymentReceiptPreview"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Reimburse Amount"
                      label-for="v-reimburse_amount"
                    >
                      <b-form-input
                        id="v-reimburse_amount"
                        :class="{
                          'border-danger':
                            formData.reimburse_amount &&
                            !correctDecimalFormat.reimburse_amount,
                        }"
                        class="text-right"
                        v-model="formData.reimburse_amount"
                        @change="convertToDecimal('reimburse_amount')"
                        @input="incorrectDecimal('reimburse_amount')"
                        @keypress="isNumber"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Reimburse Reference"
                      label-for="v-reimburse_reference"
                    >
                      <b-form-input
                        id="v-reimburse_reference"
                        v-model="formData.reimburse_reference"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Reimburse Date"
                      label-for="v-reimburse_date"
                    >
                      <b-form-input
                        id="v-reimburse_date"
                        v-model="formData.preview_reimburse_date"
                        type="datetime-local"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Certificate Start Date"
                      label-for="v-certificate_start_date"
                    >
                      <b-form-input
                        id="v-certificate_start_date"
                        v-model="formData.preview_certificate_start_date"
                        type="datetime-local"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Certificate End Date"
                      label-for="v-certificate_expired_date"
                    >
                      <b-form-input
                        id="v-certificate_expired_date"
                        v-model="formData.preview_certificate_expired_date"
                        type="datetime-local"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="Certificate" label-for="v-certificate">
                      <b-form-file
                        id="v-certificate"
                        v-model="fileUploadCertificate"
                      ></b-form-file>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="certificatePreview">
                  <b-col>
                    <b-form-group
                      label="Certificate Preview"
                      label-for="v-certificate_preview"
                    >
                      <b-img
                        center
                        thumbnail
                        fluid
                        :src="certificatePreview"
                        v-if="certificateType"
                      />

                      <iframe
                        v-else
                        :src="certificatePreview"
                        height="1200px"
                        width="100%"
                      ></iframe>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-right">
                    <b-button
                      type="button"
                      class="mr-1"
                      variant="outline-danger"
                      @click="goBack()"
                    >
                      Back
                    </b-button>
                    <b-button
                      :disabled="isLoading"
                      v-b-modal.tab-information
                      type="button"
                      variant="primary"
                    >
                      {{ onUpdate }}
                    </b-button>

                    <Modal
                      modalId="tab-information"
                      @onChange="(v) => onSubmit()"
                    />
                  </b-col>
                </b-row>
              </b-tab>
              <span v-if="$route.query.id">
                <b-tab title="Logs">
                  <Logs ref="logsRef" module="trainingmanagements" />
                </b-tab>
                <b-tab title="Comments">
                  <Comments ref="commentsRef" module="trainingmanagements" />
                </b-tab>
              </span>
            </b-tabs>
          </b-card>
        </validation-observer>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import trainingManagementApi from '@api/training_managements';
import trainingApi from '@api/trainings';
import usersApi from '@api/users';
import statusesApi from '@api/statuses';
import { queryParameters } from '@/schema';

export default {
  components: {},
  data: () => ({
    activeTab: 0,
    isLoading: false,
    correctDecimalFormat: {
      payment_amount: true,
      reimburse_amount: true,
    },
    users: [],
    trainings: [],
    trainingStatuses: [],
    paymentStatuses: [],
    fileUploadCertificate: null,
    fileUploadPaymentReceipt: null,
    formData: {
      id: null,
      employee_id: 0,
      due_date: null,
      exam_date: null,
      exam_status: null,
      payment_amount: '0.00',
      payment_reference: null,
      payment_receipt: null,
      payment_status: null,
      reimburse_amount: '0.00',
      reimburse_reference: null,
      reimburse_date: null,
      certificate: null,
      certificate_start_date: null,
      certificate_expired_date: null,

      preview_due_date: null,
      preview_exam_date: null,
      preview_reimburse_date: null,
      preview_certificate_start_date: null,
      preview_certificate_expired_date: null,
      certificate_base64: null,
      certificate_type: null,
      payment_receipt_base64: null,
    },
  }),
  computed: {
    onUpdate: {
      get() {
        return this.formData.id ? 'Update' : 'Create';
      },
    },
    certificateType: {
      get() {
        return this.formData.certificate_base64.includes('image');
      },
    },
    certificatePreview: {
      get() {
        const { id, certificate, certificate_base64: base64 } = this.formData;
        const { VUE_APP_ROOT_STATIC_URL: staticURL } = process.env;

        if (base64) {
          return base64;
        }

        if (!base64 && certificate) {
          return `${staticURL}/training_management/${id}/${certificate}`;
        }

        return null;
      },
    },
    paymentReceiptPreview: {
      get() {
        const {
          id,
          payment_receipt,
          payment_receipt_base64: base64,
        } = this.formData;
        const { VUE_APP_ROOT_STATIC_URL: staticURL } = process.env;

        if (base64) {
          return base64;
        }

        if (!base64 && payment_receipt) {
          return `${staticURL}/training_management/${id}/${payment_receipt}`;
        }

        return null;
      },
    },
  },
  watch: {
    activeTab(v) {
      if (v === 1) {
        this.$refs.logsRef.loadLogs();
      }

      if (v === 2) {
        this.$refs.commentsRef.loadTimelineHeader();
      }
    },
    fileUploadCertificate(e) {
      if (e) {
        const reader = new window.FileReader();
        reader.readAsDataURL(e);
        reader.onload = () => {
          this.formData.certificate = e.name;
          this.formData.certificate_base64 = reader.result;
          this.formData.certificate_type = e.type;
        };
      }
    },
    fileUploadPaymentReceipt(e) {
      if (e) {
        const reader = new window.FileReader();
        reader.readAsDataURL(e);
        reader.onload = () => {
          this.formData.payment_receipt = e.name;
          this.formData.payment_receipt_base64 = reader.result;
        };
      }
    },
  },
  mounted() {
    this.loadSystemCode();
    this.loadUsers();
    this.loadTrainings();

    if (+this.$route.query.id) {
      this.loadTrainingManagements();
    }

    this.incorrectDecimal();
  },
  methods: {
    loadUsers() {
      const params = {
        sort: 'first_name',
        page: { size: 1000 },
        status: queryParameters.status,
        filterExt: `roles=user&sales&admin&tech`,
      };

      usersApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.users = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadTrainings() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
      };

      trainingApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            const items = [];
            for (const item of data) {
              if (this.formData && this.formData.new_code !== item.new_code) {
                items.push({
                  text: `${item.name} - ${item.new_code}`,
                  value: item.new_code,
                });
              }
            }

            this.trainings = items;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadTrainingManagements() {
      trainingManagementApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          if (data != null) {
            const {
              due_date,
              exam_date,
              reimburse_date,
              certificate_start_date,
              certificate_expired_date,
              payment_amount,
              reimburse_amount,
            } = data;

            const fromUnix = (date) => {
              if (date) {
                return this.inputDateTimeFromUnix(date);
              }

              return null;
            };

            data.preview_due_date = fromUnix(due_date);
            data.preview_exam_date = fromUnix(exam_date);
            data.preview_reimburse_date = fromUnix(reimburse_date);
            data.preview_certificate_start_date = fromUnix(
              certificate_start_date
            );
            data.preview_certificate_expired_date = fromUnix(
              certificate_expired_date
            );
            if (payment_amount) {
              data.payment_amount = payment_amount.toFixed(2);
            }
            if (reimburse_amount) {
              data.reimburse_amount = reimburse_amount.toFixed(2);
            }

            this.formData = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadSystemCode() {
      const params = {
        sort: '-created_at',
        page: { size: 1000 },
        status: 'O',
        filterExt: 'code=trainingmanagement:status|trainingmanagement:payment',
      };

      statusesApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            const [status] = data.filter((x) => {
              return x.code === 'trainingmanagement:status';
            });
            if (status) {
              this.trainingStatuses = status.selections;
            }

            const [payment] = data.filter((x) => {
              return x.code === 'trainingmanagement:payment';
            });
            if (payment) {
              this.paymentStatuses = payment.selections;
            }
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      let app;

      const formData = this.formData;
      const {
        preview_due_date,
        preview_exam_date,
        preview_reimburse_date,
        preview_certificate_start_date,
        preview_certificate_expired_date,
        payment_amount,
        reimburse_amount,
        employee_id,
      } = formData;

      const toUnix = (date) => {
        if (date) {
          return this.inputDateTimeToUnix(date);
        }

        return null;
      };

      formData.employee_id = parseInt(employee_id);
      formData.payment_amount = parseFloat(payment_amount || 0);
      formData.reimburse_amount = parseFloat(reimburse_amount || 0);
      formData.due_date = toUnix(preview_due_date);
      formData.exam_date = toUnix(preview_exam_date);
      formData.reimburse_date = toUnix(preview_reimburse_date);
      formData.certificate_start_date = toUnix(preview_certificate_start_date);
      formData.certificate_expired_date = toUnix(
        preview_certificate_expired_date
      );

      if (this.formData.id) {
        app = trainingManagementApi.update(formData);
      } else {
        app = trainingManagementApi.add(formData);
      }

      if (app) {
        app
          .then(({ data }) => {
            this.toastConfig();
            const url = `/pages/masterlists/training-management/view?id=${data.id}`;
            this.$router.push(url);
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
    convertToDecimal(field) {
      const payment_amount = this.formData[field];

      if (payment_amount != '' && this.correctMoneyFormat(payment_amount)) {
        const toDecimal = this.moneyFormat(payment_amount).replace('$', '');

        const valueIsNan = isNaN(toDecimal.replaceAll(',', ''));
        this.formData[field] = valueIsNan ? '0.00' : toDecimal;

        return;
      }

      this.formData[field] = '0.00';
      this.incorrectDecimal();
    },
    incorrectDecimal(field) {
      const payment_amount = this.formData[field];
      this.correctDecimalFormat[field] =
        this.correctMoneyFormat(payment_amount);
    },
    isNumber(event) {
      if (isNaN(event.key) && event.key !== '.' && event.key !== 'Backspace') {
        event.preventDefault();
      }

      if (event.code === 'Space') {
        event.preventDefault();
      }
    },
    goBack() {
      this.$router.push('/pages/masterlists/training-management');
    },
  },
};
</script>

<style></style>
