<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col cols="12" md="2">
        <b-form-group>
          <label for="">Today: </label>

          <b-input-group>
            <b-input-group-prepend
              is-text
              class="cursor-pointer"
              @click="onPreviousDay()"
            >
              <feather-icon icon="ChevronLeftIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="v-job_eta"
              @change="onChangeData($event)"
              v-model="filterByDate"
              type="date"
              class="form-control text-center"
            />

            <b-input-group-append
              is-text
              class="cursor-pointer"
              @click="onNextDay()"
            >
              <feather-icon icon="ChevronRightIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="9">
        <b-form-group>
          <label for="">Engineers: </label>
          <v-select
            style="background-color: #ffffff"
            multiple
            id="v-techinician_id"
            :options="users"
            :reduce="(users) => users.value"
            :closeOnSelect="false"
            @option:selected="onSelectTechnician($event)"
            @option:deselected="onDeselectTechnician($event)"
          >
            <template #option="{ label, has_jobs }">
              <div :class="has_jobs > 0 ? 'text-danger' : ''">
                {{ label }}
              </div>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="1">
        <b-form-group>
          <label for="">More Filters</label>
          <b-button block size="md" @click="moreFilters = !moreFilters">
            <feather-icon icon="FilterIcon" />
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>

    <span v-if="moreFilters">
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group>
            <label for="">Date Range </label>

            <b-input-group>
              <b-input-group-prepend is-text> From </b-input-group-prepend>
              <b-form-input
                @change="onChangeData($event)"
                id="v-from_date"
                v-model="fromDate"
                type="datetime-local"
                class="form-control text-center"
              />

              <b-input-group-append is-text> To </b-input-group-append>
              <b-form-input
                id="v-to_date"
                v-model="toDate"
                type="datetime-local"
                class="form-control text-center"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group>
            <label for="">Job Status</label>
            <v-select
              style="background-color: #ffffff"
              multiple
              id="v-job_status"
              v-model="jobStatus"
              :options="jobStatusOptions"
              :reduce="(jobStatusOptions) => jobStatusOptions.value"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="2">
          <b-form-group>
            <label for="">Service Location</label>
            <v-select
              style="background-color: #ffffff"
              multiple
              id="v-service_location"
              v-model="filterByServiceLocation"
              :options="serviceLocationsOptions"
              :reduce="
                (serviceLocationsOptions) => serviceLocationsOptions.value
              "
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group>
            <label for="">Company</label>
            <v-select
              style="background-color: #ffffff"
              multiple
              id="v-company_id"
              ref="companyRef"
              :options="companies"
              :reduce="(companies) => companies.value"
              :closeOnSelect="false"
              :clearable="true"
              @option:selected="onSelectCompany($event)"
              @option:deselected="onDeselectCompany($event)"
            >
              <template #selected-option="{ item }">
                {{ item.company_id }} - {{ item.company_name }}...
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Address" label-for="v-address">
            <b-form-input
              id="v-address"
              v-model="filterByAddress"
              list="datalistAddresses"
              @input="search($event)"
              @change="onChangeData($event)"
            />
            <datalist id="datalistAddresses">
              <option v-for="(item, index) in items" :key="index">
                {{ item.ADDRESS }}
              </option>
            </datalist>
          </b-form-group>
        </b-col>
      </b-row>
    </span>

    <b-row>
      <b-col v-if="showNew" :cols="isBreakpoint ? '12' : ''">
        <b-card class="border-top-primary">
          <b-card-text>
            <b-form-group>
              <div class="text-left">
                <h5>New Jobs</h5>
              </div>
            </b-form-group>

            <b-row v-for="(item, index) in dataSourceNew" :key="index">
              <b-col>
                <JobCard :data-source="item" @form-data="(v) => onGetData(v)" />
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col v-if="showAssigned" :cols="isBreakpoint ? '12' : ''">
        <b-card class="border-top-info">
          <b-card-text>
            <b-form-group>
              <div class="text-left">
                <h5>Assigned</h5>
              </div>
            </b-form-group>

            <b-row v-for="(item, index) in dataSourceAccepted" :key="index">
              <b-col>
                <JobCard :data-source="item" @form-data="(v) => onGetData(v)" />
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col v-if="showStart" :cols="isBreakpoint ? '12' : ''">
        <b-card class="border-top-warning">
          <b-card-text>
            <b-form-group>
              <div class="text-left">
                <h5>Start</h5>
              </div>
            </b-form-group>

            <b-row v-for="(item, index) in dataSourceStart" :key="index">
              <b-col>
                <JobCard :data-source="item" @form-data="(v) => onGetData(v)" />
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col v-if="showEnd" :cols="isBreakpoint ? '12' : ''">
        <b-card class="border-top-success">
          <b-card-text>
            <b-form-group>
              <div class="text-left">
                <h5>End</h5>
              </div>
            </b-form-group>

            <b-row v-for="(item, index) in dataSourceEnd" :key="index">
              <b-col>
                <JobCard :data-source="item" @form-data="(v) => onGetData(v)" />
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col v-if="showCompleted" :cols="isBreakpoint ? '12' : ''">
        <b-card class="border-top-secondary">
          <b-card-text>
            <b-form-group>
              <div class="text-left">
                <h5>Submitted</h5>
              </div>
            </b-form-group>

            <b-row v-for="(item, index) in dataSourceSubmitted" :key="index">
              <b-col>
                <JobCard :data-source="item" @form-data="(v) => onGetData(v)" />
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import joborderHeaderApi from '@api/joborder_headers';
import usersApi from '@api/users';
import companiesApi from '@api/companies';
import { queryParameters } from '@/schema';
import axios from '@axios';

export default {
  name: 'TaskListPage',
  components: {},
  data: () => ({
    dataSourceNew: [],
    dataSourceAccepted: [],
    dataSourceStart: [],
    dataSourceEnd: [],
    dataSourceSubmitted: [],
    users: [],
    companies: [],
    items: [],
    jobStatusOptions: [
      { label: 'New', value: '0' },
      { label: 'Assigned', value: '1' },
      { label: 'Start', value: '2' },
      { label: 'End', value: '4' },
      { label: 'Completed', value: '5' },
    ],
    serviceLocationsOptions: [
      { label: 'On-Site', value: 'O' },
      { label: 'Remote', value: 'R' },
    ],
    technicianSelected: 0,
    filterByDate: null,
    filterByCompany: null,
    filterByTechnician: null,
    filterByAddress: null,
    filterByServiceLocation: null,
    fromDate: null,
    toDate: null,
    jobStatus: null,
    companySelected: null,
    moreFilters: false,
    showNew: true,
    showAssigned: true,
    showStart: true,
    showEnd: true,
    showCompleted: true,
    oneMapUrl: `https://www.onemap.gov.sg/api/common/elastic/search?returnGeom=Y&getAddrDetails=Y&searchVal`,
    isBreakpoint: false,
  }),
  watch: {
    jobStatus(v) {
      if (v.length === 0) {
        this.showNew = true;
        this.showAssigned = true;
        this.showStart = true;
        this.showEnd = true;
        this.showCompleted = true;

        return;
      }

      this.showNew = v.includes('0');
      this.showAssigned = v.includes('1');
      this.showStart = v.includes('2');
      this.showEnd = v.includes('4');
      this.showCompleted = v.includes('5');
    },
    fromDate(v) {
      const [date] = v.split('T');

      if (this.moreFilters) {
        this.filterByDate = date;
        this.loadJobOrders();
      }
    },
    toDate(v) {
      if (this.moreFilters) {
        this.loadJobOrders();
      }
    },
    filterByServiceLocation(v) {
      if (this.moreFilters) {
        this.loadJobOrders();
      }
    },
  },
  mounted() {
    this.filterByDate = this.inputDateTimeToLocale().split('T')[0];
    this.fromDate = this.inputDateTimeToLocale();
    this.toDate = this.inputDateTimeToLocale();
    this.loadUsers();
    this.loadCompanies();
    this.loadJobOrders();

    window.addEventListener('resize', ({ target }) => {
      const { innerWidth } = target;

      this.isBreakpoint = innerWidth <= 1820;
    });
  },
  methods: {
    loadJobOrders() {
      const params = {};

      const paramsFilter = [];
      if (this.filterByTechnician) {
        paramsFilter.push(`job_assigned_to_1=${this.filterByTechnician}`);
      }

      const filterStart = `${this.filterByDate} 00:00:00.000 +0800`;
      const filterEnd = `${this.filterByDate} 23:59:00.000 +0800`;
      const formatFrom = `${this.fromDate.replace('T', ' ')}:00.000 +0800`;
      const formatTo = `${this.toDate.replace('T', ' ')}:00.000 +0800`;
      const from = this.moreFilters ? formatFrom : filterStart;
      const to = this.moreFilters ? formatTo : filterEnd;
      paramsFilter.push(`job_eta=${from}|${to}`);

      if (this.filterByCompany) {
        paramsFilter.push(`company_id=${this.filterByCompany}`);
      }

      if (this.filterByAddress) {
        paramsFilter.push(`address=${this.filterByAddress}`);
      }

      if (this.filterByServiceLocation) {
        paramsFilter.push(`service_location=${this.filterByServiceLocation}`);
      }

      if (paramsFilter.length > 0) {
        params.filterExt = paramsFilter.join(',');
      }

      joborderHeaderApi
        .getbyjobstatus(params)
        .then(({ data }) => {
          const { new: newJobs, assigned, start, end, submitted } = data;
          this.dataSourceNew = newJobs.data;
          this.dataSourceAccepted = assigned.data;
          this.dataSourceStart = start.data;
          this.dataSourceEnd = end.data;
          this.dataSourceSubmitted = submitted.data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadUsers() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
        filterExt: `roles=user&coyuser&custuser&tech`,
      };

      usersApi
        .list(params)
        .then(({ data }) => {
          const items = [];

          for (const item of data) {
            let fullName = item.first_name;
            if (item.last_name) {
              fullName += ` ${item.last_name}`;
            }

            items.push({ label: fullName, value: +item.id, item });
          }

          this.users = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadCompanies() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
      };

      companiesApi
        .list(params)
        .then(({ data }) => {
          const items = [];

          for (const item of data) {
            const { id, company_id, company_name } = item;
            items.push({
              label: `${company_id} - ${company_name}`,
              value: +id,
              item,
            });
          }

          this.companies = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSelectTechnician(item) {
      this.technicianSelected = item.flatMap((x) => {
        return x.value;
      });

      this.filterByTechnician = this.technicianSelected.join('|');
      this.loadJobOrders();
    },
    onDeselectTechnician(item) {
      this.technicianSelected = this.technicianSelected.filter((x) => {
        return x !== item.value;
      });

      this.filterByTechnician = this.technicianSelected.join('|');
      this.loadJobOrders();
    },
    onSelectCompany(item) {
      this.companySelected = item.flatMap((x) => {
        return x.value;
      });

      this.filterByCompany = this.companySelected.join('|');
      this.loadJobOrders();

      const companyInput = this.$refs.companyRef;
      console.log(companyInput);
      // const items = companyInput.selectedValue
      // for (const item of items) {
      //   item.label = "..."
      // }
    },
    onDeselectCompany(item) {
      this.companySelected = this.companySelected.filter((x) => {
        return x !== item.value;
      });

      this.filterByCompany = this.companySelected.join('|');
      this.loadJobOrders();
    },
    onPreviousDay() {
      const dateToUnix = this.subtractToDateTime(
        1,
        'day',
        new Date(this.filterByDate),
        'en-CA'
      );
      this.filterByDate = dateToUnix.split('T')[0];
      this.loadJobOrders();
    },
    onNextDay() {
      const dateToUnix = this.addToDateTime(
        1,
        'day',
        new Date(this.filterByDate),
        'en-CA'
      );
      this.filterByDate = dateToUnix.split('T')[0];
      this.loadJobOrders();
    },
    onGetData(data) {
      const { id } = data;

      const url = `/pages/services-tools/job-order/view?id=${id}`;
      window.open(url, '_blank');
    },
    onChangeData(data) {
      this.loadJobOrders();
    },
    search(keyword) {
      axios
        .get(`${this.oneMapUrl}=${keyword}`)
        .then(({ data }) => {
          const { results } = data;
          this.items = results;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style lang="scss">
.border-top-primary {
  border-top: 5px solid #007bff;
}

.border-top-info {
  border-top: 5px solid #00cfe8;
}

.border-top-warning {
  border-top: 5px solid #ff9f43;
}

.border-top-secondary {
  border-top: 5px solid #82868b;
}

.border-top-success {
  border-top: 5px solid #28c76f;
}
</style>
